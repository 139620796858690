import React from "react";
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import Add from '@mui/icons-material/Add';
import CollectionsIcon from '@mui/icons-material/Collections';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventIcon from '@mui/icons-material/Event';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SchoolIcon from '@mui/icons-material/School';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import QuizIcon from '@mui/icons-material/Quiz';
import PollIcon from '@mui/icons-material/Poll';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import InventoryIcon from '@mui/icons-material/Inventory';
import Assessment from '@mui/icons-material/Assessment';
import Records from '@mui/icons-material/ReceiptLong';
import MediaIcon from '@mui/icons-material/PermMedia';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import MonitoringPlatformIcon from "../Icons/MonitornigPlatform";
import {
  CheckBox,
  CheckCircle, Delete,
  FiberNew, PostAdd,
  SentimentDissatisfied,
  Unpublished
} from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import MonitoringLOIcon from "../Icons/MonitornigLO";

function Icon({ type, ...props }) {
  switch(type) {
    case "AddIcon":
      return <Add {...props}/>
    case "PostAddIcon":
      return <PostAdd {...props}/>
    case "ListIcon":
      return <ListIcon {...props}/>
    case "AddBoxTwoToneIcon":
      return <AddBoxTwoToneIcon {...props}/>
    case "CollectionsIcon":
      return <CollectionsIcon {...props}/>
    case "CollectionsBookmarkIcon":
      return <CollectionsBookmarkIcon {...props}/>
    case "EditOutlinedIcon":
      return <EditOutlinedIcon {...props}/>
    case "EventIcon":
      return <EventIcon {...props}/>
    case "KeyboardDoubleArrowLeftIcon":
      return <KeyboardDoubleArrowLeftIcon {...props}/>
    case "KeyboardDoubleArrowRightIcon":
      return <KeyboardDoubleArrowRightIcon {...props}/>
    case "NewspaperIcon":
      return <NewspaperIcon {...props}/>
    case "SchoolIcon":
      return <SchoolIcon {...props}/>
    case "VideoLibraryIcon":
      return <VideoLibraryIcon {...props}/>
    case "SentimentDissatisfiedIcon":
      return <SentimentDissatisfied {...props}/>
    case "CheckCircleIcon":
      return <CheckCircle {...props}/>
    case "UnpublishedIcon":
      return <Unpublished {...props}/>
    case "FiberNewIcon":
      return <FiberNew {...props}/>
    case "CheckBoxIcon":
      return <CheckBox {...props}/>
    case "DeleteIcon":
      return <Delete {...props}/>
	  case "CalendarIcon":
		  return <CalendarMonthIcon {...props}/>
	  case "QuestionIcon":
		  return <QuizIcon {...props}/>
	  case "SurveyIcon":
		  return <PollIcon {...props}/>
	  case "CalendarInvitationIcon":
		  return <InsertInvitationIcon {...props}/>
	  case "PersonIcon":
		  return <PersonIcon {...props}/>
	  case "TrashIcon":
			return <DeleteIcon {...props}/>
	  case "MenuIcon":
			return <MenuIcon {...props}/>
	  case "PackageIcon":
			return <InventoryIcon {...props}/>
	  case "Assessment":
			return <Assessment {...props}/>
	  case "Record":
		  return <Records {...props}/>
	  case "MediaIcon":
		  return <MediaIcon {...props}/>
	  case "CloseIcon":
		  return <CloseIcon {...props}/>
	  case "SmileIcon":
		  return <SentimentSatisfiedAltIcon{...props}/>
	  case "SadIcon":
		  return <SentimentVeryDissatisfiedIcon {...props}/>
	  case "FileIcon":
		  return <TextSnippetIcon {...props}/>
	  case "DownloadIcon":
		  return <DownloadIcon {...props}/>
    case "MonitoringPlatform":
      return <MonitoringPlatformIcon />
    case "MonitoringLO":
      return <MonitoringLOIcon />
    case "EastIcon":
      return <EastIcon {...props}/>
    case "WestIcon":
      return <WestIcon {...props}/>
    default:
    return <></>
  }
};

export default Icon;
