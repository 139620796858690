import { createBrowserRouter } from "react-router-dom";
import Assign from "routes/Assign";
import Requests from "routes/Requests";
import Contents from "routes/Contents";
import ContentsForm from "routes/ContentsForm";
import ContentsFormEdit from "routes/ContentsFormEdit";
import DasboardHome from "routes/DashboardHome";
import Examples from "routes/Examples";
import Root from "routes/Root";
import React from "react";
import CreatePost from "routes/CreatePost";
import UploadRecords from "routes/UploadRecords";
import MediaContents from "routes/MediaContents";
import FileContents from "routes/FileContents";
import Reports from "routes/Reports";
import Monitoring from "@/routes/Monitoring"

import { CONTENT_TYPES } from "./api/config";

export default createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: 'assign',
        element: <Assign/>,
      },
      {
        path: 'requests',
        element: <Requests/>,
      },
	    {
		    path: 'newsFeed',
		    element: <CreatePost/>,
	    },
	    {
		    path: 'upload_records',
		    element: <UploadRecords/>,
	    },
	    {
		    path: 'report',
		    element: <Reports/>,
	    },
	    {
		    path: 'monitoring',
		    element: <Monitoring/>,
	    },
	    {
		    path: 'media',
		    element: <MediaContents/>,
	    },
	    {
		    path: 'file',
		    element: <FileContents/>,
	    },
      {
        path: '/',
        element: <DasboardHome />,
      },
      {
        path: 'test',
        element: <Examples />,
      },
      ...createContentRoutes(),
      ...editContentRoutes(),
      ...getContentRoutes(),
    ],
  }
]);

function getContentRoutes() {
  return (
    CONTENT_TYPES.map((contentType) => (
      {
        key: contentType.id,
        path: contentType.path.replace(/\s/, '-'),
          // replaces whitespaces with hyphens
        element: <Contents contentType={contentType.id}  contentTypeTitle={contentType.title}/>,
      }
    ))
  )
};

function editContentRoutes() {
  return (
    CONTENT_TYPES.map((contentType) => (
      {
        key: contentType.id,
        path: contentType.path.replace(/\s/, '-') + '/:id',
          // replaces whitespaces with hyphens
        element: <ContentsFormEdit />,
      }
    ))
  )
};

function createContentRoutes() {
  return (
    CONTENT_TYPES.map((contentType) => (
      {
        key: contentType.id,
        path: contentType.path.replace(/\s/, '-') + '/create',
          // replaces whitespaces with hyphens
        element: <ContentsForm />,
      }
    ))
  )
};