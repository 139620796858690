import useEnvironment from "@/api/useEnvironment";
import useRoutes from "api/useRoutes";

function useRequests() {
	const { preliosDomainUrl } = useRoutes();
	const domainUrl = preliosDomainUrl();  //  TO DO CHANGE WITH window.location.origin;
	const {environment} = useEnvironment();

    let baseUrl;
    switch (environment) {
        case "dev":
                baseUrl = "https://s4b2apidev.ispfdevelop.com";
                break;
        case "prod":
                baseUrl = "https://api.skills4business.it";
                break;
        case "sit":
                baseUrl = "https://s4b2api.ispfdevelop.com";
                break;
        case "mock":
                baseUrl = '';
                break;
        default:
            throw new Error(`unknown environment ${environment}`);
    };
	
	async function requestResponse(valuesToSend, url, method, noDrupal, authorization, uploadCsv, uploadImg) {
		if(uploadCsv || uploadImg) {
			const res = await fetch(
				baseUrl + url,
				{
					body: valuesToSend,
					headers: uploadImg ? {
                        //"content-type": "multipart/form-data",
                        "x-api-key": "jWfdyaIOLf8aCLvYd1HAS1iOb2Ddh3LaaktgDpVK",
                        "authorization": authorization,
                        "domain": 2485,
                    } :
                    {
                        "x-api-key": "jWfdyaIOLf8aCLvYd1HAS1iOb2Ddh3LaaktgDpVK",
                        "authorization": authorization,
                        "domain": 2485,
                    },
					method: method
				}
			)
			if (!res.ok) {
				let json = await res.json();
				json = {...json, status: 'error'}
				
				return json
				
			} else {
				let json = await res.json();
				json = {...json, status: 'success'}
				return json
			}
		}
		else if (noDrupal && !uploadImg) {
			const res = await fetch(
				baseUrl + url,
				{
					body: JSON.stringify(valuesToSend),
					headers: {
						"content-type": "application/json",
						"x-api-key": "jWfdyaIOLf8aCLvYd1HAS1iOb2Ddh3LaaktgDpVK",
						"authorization": authorization,
						"domain": 2485,
					},
					method: method
				}
			)
			if (!res.ok) {
				let json = await res.json();
				json = {...json, status: 'error'}
				
				return json
				
			} else {
				let json = await res.json();
				json = {...json, status: 'success'}
				return json
			}
			
		} else {
			const res = await fetch(
			baseUrl + "/proxy/api/v1/public_proxy",
				{
					body:
						JSON.stringify({
							body: JSON.stringify(valuesToSend),
							endpoint: url,
							method: method,
							domainUrl: domainUrl
						}),
					headers: {
						"content-type": "application/json",
						"x-api-key": "jWfdyaIOLf8aCLvYd1HAS1iOb2Ddh3LaaktgDpVK",
					},
					method: "POST"
				}
			)
			if (!res.ok) {
				let json = await res?.json();
				json = {...json, status: 'error'}
				return json
			} else {
				let json = await res?.json();
				json = {...json, status: 'success'}
				return json
			}
		}
	};
	
	const getRequest = (valuesToSend, url, method, noDrupal = false, authorization = null, uploadCsv=false, uploadImg=false) => {
		return valuesToSend.map(value => {
			return new Promise((resolve, reject) => {
				requestResponse(value, url, method, noDrupal, authorization, uploadCsv, uploadImg)
					?.then(res => res)
					?.then(data => {
						resolve(data)
					})
					?.catch(error => {
						reject(error)
					})
			});
		})
		
	}
	
	return environment === "mock" ? getRequest : getRequest
	
}

export default useRequests;
