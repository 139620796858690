import { useEffect, useState } from 'react';
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  Stack,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import useFetcher from 'api/fetcher';
import useRoutes from 'api/useRoutes';
import NoResults from "components/NoResults";
import AssignStep1 from "components/AssignStep1";
import AssignStep2 from "components/AssignStep2";
import AssignStep3 from "components/AssignStep3";
import Spinner from "components/Spinner";
import createContext from 'utils/createContext';
import { FETCHER_MODE } from 'utils/FetcherModes';
import useCrypt from "@/utils/crypt";

export const { ContextProvider, useContext } = createContext({
  activeStep: 0,
  managerId: null,
  maximumSessionSeats: -1,
  pageSize: 30,
  selectedCalendar: null,
  selectedPackage: { itemId: null, shouldAssign: undefined },
  selectedUsers: {},
});

function Assign() {
  const authorization = localStorage.getItem("Authorization");
  const userId = localStorage.getItem("UserId");

  const fetcher = useFetcher();
  const [activeStep, setActiveStep] = useContext("activeStep")
  const [, setManagerId] = useContext("managerId")
  const [selectedCalendar] = useContext("selectedCalendar")
  const [selectedPackage] = useContext("selectedPackage")
  const [selectedUsers] = useContext("selectedUsers")
  const { getUserInfoEndpoint, postAssignmentEndpoint } = useRoutes();
  const [toastIsListening, setToastIsListening] = useState(false);

  const userInfoEndpoint = getUserInfoEndpoint();
  const { data: userInfo } = useQuery({
    queryKey: ["userInfo", authorization, userId],
		queryFn: () => fetcher(FETCHER_MODE.MIDDLEWARE)(
      userInfoEndpoint,
      {
        authorization,
        method: "GET",
        userId
      }
    ),
	});

  const backofficeId = localStorage.getItem('BackofficeId');
  const assignmentEndpoint = postAssignmentEndpoint(backofficeId, localStorage.getItem('UserId'))
  const {aesEncrypt} = useCrypt();
  const {
    error: createError,
    isError: createHasError,
    isLoading: createLoading,
    isSuccess: createHasSuccess,
    mutate: createAssignment
  } = useMutation({
    mutationFn: (body) => (
      fetcher(FETCHER_MODE.MIDDLEWARE)(
        assignmentEndpoint,
        {
          authorization,
          body: {body: aesEncrypt(body)},
          method: "POST",
          userId,
        }
      )
    )
  })

  useEffect(() => {
    if(!Boolean(userInfo?.userDomainAttributes)) return;

    const newManagerId =
      Array.isArray(userInfo?.userDomainAttributes)
        ? userInfo.userDomainAttributes[0].managerId
        : undefined

    setManagerId(newManagerId)
  }, [setManagerId, userInfo])

  useEffect(() => {
    /**
     * this is a bit sketchy, the state rotation is:
     * launch mutation -> loading is true -> toast is listening is true -> mutation resolves ->
     * success or error become true and can be checked together with toast open ->
     * when toast is closed toast is listening is reset to false
     */
    if (createLoading) {
      setToastIsListening(true)
    }
  }, [createLoading])

  const steps = ['Pacchetto', 'Sessione', 'Assegnazione'];

  return false ? (
    <Spinner />
  ) : (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        //autoHideDuration={6 * 1000}
        onClose={() => setToastIsListening(false)}
        open={toastIsListening && createHasSuccess}
      >
        <MuiAlert
          elevation={6}
          onClose={() => setToastIsListening(false)}
          severity="success"
          variant="filled"
        >
          creazione andata a buon fine
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        //autoHideDuration={6 * 1000}
        onClose={() => setToastIsListening(false)}
        open={toastIsListening && createHasError}
      >
        <MuiAlert
          elevation={6}
          onClose={() => setToastIsListening(false)}
          severity="error"
          variant="filled"
        >
          {createError?.message}
        </MuiAlert>
      </Snackbar>
      <Stack
        justifyContent="center"
        minHeight="100vh"
        paddingX={4}
      >
        {/* {Array.isArray(contents?.catalog) && contents?.catalog.length > 0 ? ( */}
        {true ? (
          <Stack minHeight="85vh" minWidth="100%">
            <Stepper activeStep={activeStep} sx={{height: '120px'}}>
              {steps.map((label) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Stack
              boxSizing="border-box"
              flexGrow={1}
              justifyContent="space-between"
              paddingX={4}
              paddingY={7}
              width="100%"
            >
              {activeStep === 0 ? (
                <AssignStep1 />
              ) : null}
              {activeStep === 1 ? (
                <AssignStep2 />
              ) : null}
              {activeStep === 2 ? (
                <AssignStep3 />
              ) : null}
            </Stack>
            <Stack direction="row" paddingY={4}>
              {activeStep > 0 ? (
                <Button
                  disabled={activeStep === 0 || activeStep === steps.length}
                  onClick={() => {
                    if (activeStep === 1) {
                      setActiveStep(0);
                    } else if (activeStep === 2 && !Boolean(selectedPackage.shouldAssign) ) {
                      setActiveStep(0);
                    } else if (activeStep === 2 && Boolean(selectedPackage.shouldAssign) ) {
                      setActiveStep(1);
                    }
                  }}
                >
                  Indietro
                </Button>
              ) : null}
              <Box flexGrow={1} />
              { activeStep < steps.length ? (
                <NextStepButton step={activeStep} />
              ) : (
                // TODO: error handling goes here
                null
              )}
            </Stack>
          </Stack>
        ) : (
          <NoResults/>
        )}
      </Stack>
    </>
  );

  //TODO: pull out of main function component once context is ready
  function NextStepButton({ step }) {
    switch(step) {
      case 1:
        return (
          <Button
            disabled={!selectedCalendar}
            onClick={() => { setActiveStep(2) }}
          >
            Avanti
          </Button>
        )
      case 2:
        return (
          <Button
            disabled={
              (Object.values(selectedUsers)
                .filter((val) => val)
                .length
              ) < 1
            }
            onClick={() => {
                let payload = Object.entries(selectedUsers)
                  .filter(([_, val]) => val)
                  .map(([key, _]) => {
                    const obj = {
                      userEmail: key,
                            itemId: selectedPackage.itemId
                    };
                    if(selectedCalendar){
                      obj.calendarId = selectedCalendar
                    }
                  return obj;
                  })
              createAssignment({
                requestItems: payload,
                userId: userId,
                backofficeId: backofficeId
              })
            }}
          >
            Invia
          </Button>
        )
      default:
        return null
    }
  }
}

export default function AssignWithContext() {
  return (
    <ContextProvider>
      <Assign />
    </ContextProvider>
  )
}
