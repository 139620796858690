import React, {useCallback, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {
	Autocomplete,
	FormHelperText,
	Grid,
	TextField,
	Typography,
	Button
} from "@mui/material";
import * as yup from "yup";
import _debounce from "lodash.debounce";
import useFetcher from "api/fetcher";
import useTheme from "api/siteTheme";
import useRoutes from "api/useRoutes";
import {resetGroupFields} from "components/InputField";

function InputSelect({
	                     contentFields,
	                     controlForm,
	                     controlGroup,
	                     controlLabel,
	                     controlName,
	                     multiple,
	                     optionsEndpoint,
	                     optionsStatic,
	                     property,
	                     isCreating,
	                     required,
	                     contentType
                     }) {
	const theme = useTheme();
	const initialValues = controlForm.values[controlName];
	const [selectInput, setSelectInput] = useState("");
	const [optionsUrl, setOptionsUrl] = useState("");
	const [pager, setPager] = useState(0);
	const [isChanged, setIsChanged] = useState(false);
	const [values, setValues] = useState(initialValues);
	const [errorMessage, setErrorMessage] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);
	const [options, setOptions] = useState([]);
	const fetcher = useFetcher();
	const {getOptionsEndpoint, getOptionsEndpointSearch} = useRoutes();
	
	useEffect(() => {
		setOptionsUrl(getOptionsEndpoint(optionsEndpoint, property, selectInput))
	}, [])
	useEffect(() => {
		if (selectInput) {
			setOptionsUrl(getOptionsEndpointSearch(optionsEndpoint, selectInput, 0));
		} else {
			setOptionsUrl(getOptionsEndpoint(optionsEndpoint, property, 0));
		}
		refetch()
	}, [selectInput])
	const {data: optionsFinded, isLoading: optionsAreLoading, refetch} = useQuery({
	    keepPreviousData: true,
		queryKey: ["options", optionsEndpoint?.replace('/', ""), optionsUrl],
		queryFn: () => fetcher(optionsUrl, {method: "GET"}),
	});

	//const options = optionsFinded?.data;
	
	useEffect(() => {
		setOptionsUrl(getOptionsEndpoint(optionsEndpoint, property, pager));
		refetch();

		const optionsUpdate = pager === 0 ? optionsFinded?.data : [...options, ...optionsFinded?.data];

		let optionsUnique = optionsUpdate?.reduce((unique, o) => {
			if(!unique.some(obj => obj.id === o.id)) {
			  unique.push(o);
			}
			return unique;
		},[]);

		setOptions(optionsUnique);
	}, [pager, optionsFinded]);

	
	const totalPages = optionsFinded?.pager.total_pages
	
	useEffect(() => {
		setValues(initialValues);
	}, [options])
	
	const handleSelectInput = useCallback(_debounce(setSelectInput, 400), []);
	const detectDisabled = () => {
		switch (true) {
			case controlName === 'levels':
				if (isCreating) {
					if (Boolean(controlForm.values?.roles.length) || controlForm.values?.new_hired) {
						setErrorMessage('non possono essere inseriti contemporaneamente  ruoli, livelli e new hired')
						return true
					} else {
						setErrorMessage('')
						return false
					}
				} else if (contentType === 'product_manager') {
					setErrorMessage(`${controlName} non può essere modificato`)
					return true
				} else {
					setErrorMessage('')
					return false
				}
			case controlName === 'roles':
				if (isCreating) {
					if (Boolean(controlForm.values?.levels.length) || controlForm.values?.new_hired) {
						setErrorMessage('non possono essere inseriti contemporaneamente ruoli, livelli e new hired')
						return true
					} else {
						setErrorMessage('')
						return false
					}
				} else if (contentType === 'product_manager') {
					setErrorMessage(`${controlName} non può essere modificato`)
					return true
				} else {
					setErrorMessage('')
					return false
				}
			default:
				setErrorMessage('')
				return false
			
		}
	}
	
	useEffect(() => {
		setIsDisabled(detectDisabled());
	}, [controlForm]);
	
	return (
		<>
			{
				Boolean(errorMessage) ? (
					<FormHelperText sx={{
						color: `${theme.palette.primary.main}`,
						paddingBottom: '10px'
					}}>
						{errorMessage}
					</FormHelperText>
				) : null
			}
			{
				!Boolean(multiple) && !Boolean(isDisabled) ? (
					<FormHelperText sx={{
						color: `${theme.palette.primary.main}`,
						paddingBottom: '10px'
					}}>
						Inserisci al massimo 1 valore
					</FormHelperText>
				) : null
			}
			<Autocomplete
				disabled={isDisabled}
				id={controlName}
				name={controlName}
				label={`${controlLabel}${required ? '*' : ''}`}
				defaultValue={[]}
				isOptionEqualToValue={(option, value) => option?.id === value?.id}
				filterOptions={
					
					(x) => {
						const result = [...x];
						if (totalPages > pager + 1) {
							result.push({name: 'button'})
						}
						return result
					}
					
				}
				getOptionLabel={(option) =>
					(controlName === 'cover' && !isChanged)
						? option?.title || values.toString().split('/').pop()
						: option?.title || option?.name || selectInput || ''
				}
				loading={optionsAreLoading}
				multiple={controlName !== 'cover'}
				noOptionsText="Nessuna opzione disponibile"
				onInputChange={(event, newSelectValue) => {
					if (event?.type === 'change') {
						handleSelectInput(newSelectValue)
					}
				}}
				options={options || optionsStatic || []}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Seleziona alcuni contenuti"
						label={`${controlLabel}${required ? '*' : ''}`}
						error={controlForm.touched[controlName]
							&& Boolean(controlForm.errors[controlName])}
						helperText={controlForm.touched[controlName]
							&& controlForm.errors[controlName]}
						sx={{
							'& .MuiOutlinedInput-root': {
								'&:hover fieldset': {
									borderColor: `${theme.palette.primary.main}`,
								},
							},
							
						}}
						disabled={isDisabled}
					/>
				)}
				
				renderOption={
					
					(props, option) => (
						option.name === 'button' ?
							<Button size={'small'} onClick={() => {
								setPager((pager) => pager + 1)
							}} sx={{margin: 'auto', display: 'flex'}}>show more</Button> :
							<li {...props}>
								<Grid container alignItems="center">
									<Grid item
										alignItems={'center'}
										display={'flex'}
										gap={'16px'}
									>
										{controlName === 'cover' ? (
											<Grid sx={{
												objectFit: 'contain',
												height: '50px',
												width: '50px',
											}}>
												<img
													src={option.uri}
													style={{
														width: '100%', height: '100%', objectFit: 'contain'
													}}
												/>
											</Grid>
										) : null}
										<Typography>
											{`${option?.title || option.name} (${option.id})`}
										</Typography>
									</Grid>
								</Grid>
							</li>
					
					)
					
					
				}
				onChange={handleChange}
				value={values || []}
				onClose={() => setPager(0)}
			/>
		</>
	
	);
	
	function handleChange(event, value, reason, details) {
		setValues(value)
		setIsChanged(true)
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		controlForm.setFieldValue(controlName, value, true);
		controlForm.setTouched({controlName: true}, true);
	}
};


export const inputSelectValidator = ({
	                                     controlLabel,
	                                     controlName,
	                                     controlMaxValues = -1,
	                                     controlMinValues = 0
                                     }) => {
	const validator = {
		[controlName]: yup
			.array("seleziona un'opzione").of(yup.object({
				id: yup.string(),
				title: yup.string(),
			}))
		
	}
	if (controlMinValues > 0) {
		validator[controlName] =
			validator[controlName]
				.required(`${controlLabel} è obbligatorio`)
				.min(controlMinValues, `Inserisci almeno ${controlMinValues} `)
	}
	if (controlMaxValues > 0) {
		validator[controlName] =
			validator[controlName]
				.max(controlMaxValues, `Inserisci al massimo ${controlMaxValues} ${controlMaxValues > 1 ? 'valori' : 'valore'}`)
	}
	return validator;
};

export default InputSelect;
