import React, {useEffect, useState} from "react";
import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	Input,
	TextField,
	Typography,
} from "@mui/material";
import {FieldArray} from 'formik';
import * as yup from "yup";
import useTheme from "api/siteTheme";
import Icon from "components/Icon";
import {resetGroupFields} from "components/InputField";


function InputMultiField({
	                         contentFields,
	                         controlForm,
	                         controlGroup,
	                         controlLabel,
	                         controlName,
	                         required
	
                         }) {
	
	const theme = useTheme();
	const [values, setValues] = useState(controlForm.values[controlName]);
	const [fieldVisible, setFieldVisible] = useState();
	const [isRequired, setIsRequired] = useState(false);

	useEffect(() => {
		if (controlName === 'answers') {
			if (controlForm.values.question_type !== 'question_multiple' && controlForm.values.question_type !== 'question_single') {
				setFieldVisible(false);
			} else {
				setFieldVisible(true);
				setIsRequired(true);
			}
		} else {
			setFieldVisible(true);
		}
	}, [controlForm])
	
	const fields = {
		link: {
			fields: [
				{
					fieldName: "link_uri",
					fieldLabel: "url link",
					fieldType: "text",
					fieldValue: "",
					required: true,
				},
				{
					fieldName: "link_title",
					fieldLabel: "nome link",
					fieldType: "text",
					fieldValue: "",
					required: true,
				}
			]
		},
		location: {
			fields: [
				{
					fieldName: "city",
					fieldLabel: "città",
					fieldType: "text",
					fieldValue: "",
					required: true,
				},
				{
					fieldName: "address",
					fieldLabel: "indirizzo",
					fieldType: "text",
					fieldValue: "",
					required: true,
				},
				{
					fieldName: "latitude",
					fieldLabel: "latitudine",
					fieldType: "text",
					fieldValue: "",
					required: true,
				},
				{
					fieldName: "longitude",
					fieldLabel: "longitudine",
					fieldType: "text",
					fieldValue: "",
					required: true,
				}
			
			]
		},
		answers: {
			fields: [
				{
					
					fieldName: 'text_content',
					fieldType: 'text',
					fieldLabel:'answer text'
					
				}
			]
		},
		documents: {
			fields: [
				{
					fieldName: 'file',
					fieldType: 'file',
				}
			]
		}
	}
	
	const contentFieldsInner = fields[controlName];
	
	const valueSchema = () => {
		switch (controlName) {
			case 'location' :
				return {
					id: 0,
					city: '',
					address: '',
					latitude: '',
					longitude: '',
					
				}
			case 'answers' :
				return {
					id: 0,
					textContent: ''
				}
			case 'link' :
				return {
					id: 0,
					link_uri: '',
					link_title: ''
				}
			default :
				return {
					id: 0,
					name: '',
					uri: '',
				}
			
		}
	}

	const isDisabled = controlName === 'link'
		? Boolean(controlForm.values.location.length > 0) || Boolean(controlForm.values.link.length > 0)
		: controlName === 'location'
			? Boolean(controlForm.values.link.length > 0) || Boolean(controlForm.values.location.length > 0)
			/*: controlName === 'answers'
				? Boolean(controlForm.values.question_type !== 'question_multiple')*/
				: false ;

	useEffect(() => {
		setValues(controlForm.values[controlName] );
	}, [controlForm,controlName, isDisabled])

	return (
		<>
			{fieldVisible && <Grid item fullwidth sx={{
				padding: '10px', borderRadius: '10px', background: '#8080801a'
			}}
		
			>
				<div style={{
					color: `${theme.palette.primary.main}`,
					padding: '16px 0',
				}}>
					{`${controlLabel}${required || isRequired ? '*':''}`}
				</div>
				
				<FieldArray
					name={`${controlName}`}
					sx={{
						border: `1px solid  ${theme.palette.primary.main}`,
						borderRadius: '10px',
						padding: '16px',
						width: '100%'
					}}
					validateOnChange={true}
				>
					{() => (
						<>
							{values?.map((item, index) => (
								<div key={index}>
									<div className="header" style={{
										padding: '16px 0',
										color: `${theme.palette.primary.main}`,
										width: '60%',
										margin: '0 auto',
										display: 'flex',
										justifyContent: 'space-between'
									}}>
										<div></div>
										<Icon type={'TrashIcon'} sx={{
											cursor: 'pointer',
											'&:hover': {color: 'rgb(35, 116, 124)'}
										}} onClick={() => {
											setValues(values.filter((value, ix) => ix !== index))
											handleChangeDelete(values.filter((value, ix) => ix !== index))
										}}/>
									</div>
									
									<Grid width={'60%'} sx={{
										borderRadius: '20px',
										padding: '16px',
										margin: '10px auto',
										boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 15px;',
										border: '1px solid #c4c4c4',
										background: 'white',
										'&:hover': {
											borderColor: `${theme.palette.primary.main}`
										}
									}}>
										{
											contentFieldsInner?.fields.map((field, ix) => {
												const valuesItemFile = item;
										       const valueItem =
												   controlName === "answers" ? (
													   !values.find(val => val.id === index)?.[field.fieldName] && values?.find(val => val.id === index)  ?
														   Object?.values(values?.find(val => val.id === index)).value?.toString() : values.find(val => val.id === index)?.[field.fieldName] )  : values.find(val => val.id === index)?.[field.fieldName];
												return (
													
													field.fieldType !== 'file' ? (
														<TextField type={'text'} key={`item-${ix}`}
																id={`item-${ix}`}
																name={field.fieldName}
																label={field.fieldLabel}
																fullWidth
																value={valueItem ? valueItem : ""}
																error={Boolean(controlForm.errors[controlName]?.[index]?.[field.fieldName]) || Boolean(controlForm.errors[controlName]?.[ix]?.[field.fieldName])}
																helperText={controlForm.errors[controlName]?.[index]?.[field.fieldName] || controlForm.errors[controlName]?.[ix]?.[field.fieldName]  }
																sx={{
																	margin: '0 0 10px',
																	'& .MuiOutlinedInput-root': {
																		'&:hover fieldset': {
																			borderColor: `${theme.palette.primary.main}`,
																		},
																	},
																}}
														
																onChange={(event) => {
																	handleChange(event.target.value, values, field.fieldName, index, ix)
																}}>
														</TextField>
													) : (
														<FormControl fullWidth>
															<Box>
																{valuesItemFile.name ?
																	<Typography
																		marginX={3}
																		sx={{ color: `${theme.palette.primary.main}` }}
																	>
																		{field.fieldName} selected :
																		<span> {valuesItemFile.name}</span>
																	</Typography> : null}
																{
																	!valuesItemFile.name ? <Input
																		sx={{
																			alignItems: "center",
																			border: 1,
																			borderColor: "#d9d9d9",
																			borderRadius: "4px",
																			display: "flex",
																			paddingY: 2,
																			justifyContent: "space-between",
																			width: "100%",
																			padding: '9px',
																			color: '#666666',
																			'input': {
																				'&::-webkit-file-upload-button': {
																					background: 'rgb(51, 167, 178)',
																					border: 'none',
																					borderRadius: '4px',
																					boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
																					marginRight: '10px',
																					cursor: 'pointer',
																					transition: 'all .1s linear',
																					color: 'white',
																					'&:hover': {
																						backgroundColor: 'rgb(35, 116, 124)',
																						boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;'
																					}
																				}
																			}
																		}}
																		id={field.fieldName}
																		name={field.fieldName}
																		label={field.fieldLabel}
																		disableUnderline
																		type="file"
																		accept="file/*"
																		onChange={(event) => {
																			handleChange(event.currentTarget.files[0], values, field.fieldName, index)
																		}}
																		error={Boolean(controlForm.errors[controlName]?.[ix]?.[field.fieldName])}
															
																	/> : null
																}
															
															</Box>
															<FormHelperText
																id={controlName}
																error={Boolean(controlForm.errors[controlName]?.[ix])}
															>
																{ controlForm.errors[controlName]?.[ix]}
															</FormHelperText>
														</FormControl>
													)
												
												
												)
												
											})
										}
									</Grid>
								</div>
							))}
							<Button
								variant="contained"
								sx={{
									color: 'white',
									background: `${theme.palette.primary.main}`,
									margin: '16px auto',
									width: 'fit-content',
									display: 'flex'
								}}
								onClick={() => {
									const added = [...values, valueSchema()];
									setValues(added)
									controlForm.setFieldValue(controlName, added, true);
								}}
								disabled={isDisabled}
							>
							Aggiungi  {controlName === 'answers' ? 'risposta' : controlName === 'files' ? 'file' : controlName === 'documents' ? 'documento' :  controlLabel }
							</Button>
						</>
					)}
				</FieldArray>
			</Grid>}
		</>
	);
	
	function handleChange(value, values, name, id, ix) {

		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
	
		
		const newValues = values.map((valueItem, index) => {
			if (index === id) {
				return {
					...valueItem,
					id: id,
					[name]: value,
				}

			} else {
				
				return {
					...valueItem
				}
			}
		});
		
		setValues(newValues)
		
		controlForm.setFieldValue(controlName, newValues, true);
		controlForm.setTouched({controlName: true}, true);
	}
	
	function handleChangeDelete(values) {
		
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		
		const newValues = values.map((valueItem, index) => {
			
			return {
				...valueItem
				
			}
			
		});
		setValues(newValues)
		
		controlForm.setFieldValue(controlName, newValues, true);
		controlForm.setTouched({controlName: true}, true);
	}
};


export const inputMultiFieldValidator = ({
	
	                                         controlName,
	                                         controlIsRequired
                                         }) => {

	let options = controlName === 'location' ? {
			city: yup.string().required("Required"),
			address: yup.string().required("Required"),
			latitude: yup.string().required("Required"),
			longitude: yup.string().required("Required"),
			
		}
		: controlName === 'link' ? {
				id: yup.number(),
				link_uri: yup.string(),
				link_title: yup.string(),
			}
			:
			controlName === 'answers' ? {
				id: yup.string(),
				text_content: controlIsRequired ? yup.string().required("Required") : yup.string()
			} : {
				id: yup.string.required("Required"),
				name: yup.string.required("Required"),
				uri: yup.string().required("Required"),
				file:yup.mixed()
					.nullable()
					.test({
						message: `${controlName} should be a file`,
						test: (file, context) => {
				
							if (file.type !== undefined) {
								const isValid = [
									'application/rtf',
									'text/plain',
									'application/pdf',
									'application/msword',
									'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
									'application/vnd.ms-powerpoint',
									'application/vnd.openxmlformats-officedocument.presentationml.presentation',
									'application/vnd.ms-excel',
									'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
									'application/pdf',
									'application/vnd.oasis.opendocument.text',
									'application/vnd.oasis.opendocument.presentation',
									'application/vnd.oasis.opendocument.spreadsheet',
								
								].includes(file.type);
								if (!isValid) {
									const type = file?.type?.match(/(?!\/)[0-9A-Za-z]+$/)[0]
									return context?.createError({
										message: `can't upload ${type} files`
									})
								}
								return isValid;
							} else {
								return true;
							}
							
						},
					})
					.test({
						message: "I want something smaller than 10MB",
						test: (file) => {
							if (file.type === undefined) {
								return true;
							} else {
								return file.size < 10_000_000 // less than 10MB
							}
							
						},
					})
			}
	

	const validator = {
		[controlName]: yup
			.array(`enter an aoption for ${controlName}`).of(yup.object(
				options
			))
	}

	return validator;
	
};

export default InputMultiField;
