import useEnvironment from "api/useEnvironment";

function useRoutes() {
	const {environment} = useEnvironment();

	const mockUrl = "http://localhost:3001";
	const proxyUrl = 'api/v1';
	
	let baseUrl;
	switch (environment) {
		case "dev":
				baseUrl = "https://s4b2apidev.ispfdevelop.com";
				break;
		case "prod":
				baseUrl = "https://api.skills4business.it";
				break;
		case "sit":
				baseUrl = "https://s4b2api.ispfdevelop.com";
				break;
		case "mock":
				baseUrl = '';
				break;
		default:
			throw new Error(`unknown environment ${environment}`);
	};

	function getSiteThemeEndpoint() {
		switch (environment) {
			case "dev":
				return `${proxyUrl}/filtered_domain`;
				case "prod":
				return `${proxyUrl}/filtered_domain`;
				case "sit":
				return `${proxyUrl}/filtered_domain`;
			case "mock":
				return `${mockUrl}/filtered_domain`;
			default:
				throw new Error(`unknown environment ${environment}`);
		}
	};

	function getOptionsEndpoint(endpoint, _, page, itemPerPage = 10) {
		switch (environment) {
		case "dev":
			return `${proxyUrl}/content_manager${endpoint}?page=${page}&items_per_page=${itemPerPage}`;
		case "prod":
			return `${proxyUrl}/content_manager${endpoint}?page=${page}&items_per_page=${itemPerPage}`;
		case "sit":
			return `${proxyUrl}/content_manager${endpoint}?page=${page}&items_per_page=${itemPerPage}`;
		case "mock":
			return `${mockUrl}${endpoint}`;
		default:
			throw new Error(`unknown environment ${environment}`);
	};
};
	
	function getOptionsEndpointSearch(endpoint, property, page) {
		switch (environment) {
			case "dev":
				return `${proxyUrl}/content_manager${endpoint}?word=${property}&page=${page}`;
			case "prod":
				return `${proxyUrl}/content_manager${endpoint}?word=${property}&page=${page}`;
			case "sit":
				return `${proxyUrl}/content_manager${endpoint}?word=${property}&page=${page}`;
			case "mock":
				return `${mockUrl}${endpoint}?${property}_like=${page}&_page=1`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};

	function getRequestsEndpoint(page, size, manager_id, backofficeId) {
		switch (environment) {
			case "dev":
                return `${baseUrl}/asset/${proxyUrl}/recommendation/filter?status=PENDING&manager_id=${manager_id}${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "prod":
                return `${baseUrl}/asset/${proxyUrl}/recommendation/filter?status=PENDING&manager_id=${manager_id}${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "sit":
                return `${baseUrl}/asset/${proxyUrl}/recommendation/filter?status=PENDING&manager_id=${manager_id}${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "mock":
				return `${mockUrl}/requests?_page=${page}&_limit=${size}`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};

	function getCatalogAssignEndpoint({ page, size, title }) {
		switch (environment) {
			case "dev":
				return `${baseUrl}/catalog/${proxyUrl}/catalog/find?${title ? `title=${title}&` : ""}${page ? `nextPage=${page}&` : ''}${size ? `itemsPerPage=${size}&` : ''}`;
			case "prod":
				return `${baseUrl}/catalog/${proxyUrl}/catalog/find?${title ? `title=${title}&` : ""}${page ? `nextPage=${page}&` : ''}${size ? `itemsPerPage=${size}&` : ''}`;
			case "sit":
				return `${baseUrl}/catalog/${proxyUrl}/catalog/find?${title ? `title=${title}&` : ""}${page ? `nextPage=${page}&` : ''}${size ? `itemsPerPage=${size}&` : ''}`;
			case "mock":
				return `${mockUrl}/catalogAssign`;

			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};

	function getContentsEndpoint(contentType, currentPage, pageSize) {
		switch (environment) {
			case "dev":
				return `${proxyUrl}/content_manager/${contentType}?page=${currentPage}&items_per_page=${pageSize}`;
			case "prod":
				return `${proxyUrl}/content_manager/${contentType}?page=${currentPage}&items_per_page=${pageSize}`;
			case "sit":
				return `${proxyUrl}/content_manager/${contentType}?page=${currentPage}&items_per_page=${pageSize}`;
			case "mock":
				return `${mockUrl}/${contentType}?_page=${currentPage}&_limit=${pageSize}`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};
	
	function getProductsEndpoint(contentType, currentPage, pageSize) {
		switch (environment) {
			case "dev":
				return `${proxyUrl}/${contentType}?page=${currentPage}`;
			case "prod":
				return `${proxyUrl}/${contentType}?page=${currentPage}`;
			case "sit":
				return `${proxyUrl}/${contentType}?page=${currentPage}`;
			case "mock":
				return `${mockUrl}/${contentType}?_page=${currentPage}`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};
	
	function getProductEndpoint(contentType, id) {
		switch (environment) {
			case "dev":
				return  id ? `${proxyUrl}/${contentType}?id=${id}` :`${proxyUrl}/${contentType}`;
			case "prod":
				return id ? `${proxyUrl}/${contentType}?id=${id}` :`${proxyUrl}/${contentType}`;
			case "sit":
				return id ? `${proxyUrl}/${contentType}?id=${id}` :`${proxyUrl}/${contentType}`;
			case "mock":
				return  id ? `${proxyUrl}/${contentType}/${id}` : `${mockUrl}/${contentType}`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};

	function getAllContentsEndpoint(contentType, currentPage) {
		switch (environment) {
			case "dev":
				return `${proxyUrl}/content_manager/${contentType}?page=${currentPage}`;
			case "prod":
				return `${proxyUrl}/content_manager/${contentType}?page=${currentPage}`;
			case "sit":
				return `${proxyUrl}/content_manager/${contentType}?page=${currentPage}`;
			case "mock":
				return `${mockUrl}/${contentType}?_page=${currentPage}`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};

	function getContentEndpoint(contentType, id) {
		switch (environment) {
			case "dev":
				return id
					? `${proxyUrl}/content_manager/${contentType}?id=${id}`
					: `${proxyUrl}/${contentType}`;
			case "prod":
				return id
					? `${proxyUrl}/content_manager/${contentType}?id=${id}`
					: `${proxyUrl}/${contentType}`;
			case "sit":
				return id
					? `${proxyUrl}/content_manager/${contentType}?id=${id}`
					: `${proxyUrl}/${contentType}`;
			case "mock":
				return id
					? `${mockUrl}/${contentType}?id=${id}`
					: `${mockUrl}/${contentType}`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};

	function getUserInfoEndpoint() {
		switch (environment) {
			case "dev":
				return `${mockUrl}/userinfo`;
			case "prod":
				return `${baseUrl}/social/${proxyUrl}/userinfo?channel=web`;
			case "sit":
				return `https://s4b2api.ispfdevelop.com/social/${proxyUrl}/userinfo?channel=web`;
			case "mock":
				return `${mockUrl}/userinfo`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	};

	function getUsersEndpoint({
		filter_by="ALL",
		filter_value,
		items=10,
		manager_id,
		new_hired,
		next_page,
		product_id,
		backofficeId
		/* productId contains the value of ZuoraProductId (restituito da catalog api) */
	}) {
		const params = {
			filter_by,
			filter_value,
			items,
			manager_id,
			new_hired,
			next_page,
			product_id,
		};
		const urlSearchParams = new URLSearchParams();
		Object.entries(params).forEach(([key, val]) => {
			/* NOTE, the loose equality is intentional and checks for null or undefined */
			if (val != undefined && val !== false) {
				urlSearchParams.append(key, val);
			}
		});
		switch (environment) {
			case "dev":
				return `${baseUrl}/social/${proxyUrl}/users/filtered?${urlSearchParams.toString()}`;
				case "prod":
				return `${baseUrl}/social/${proxyUrl}/users/filtered?${urlSearchParams.toString()}`;
				case "sit":
				return `${baseUrl}/social/${proxyUrl}/users/filtered?${urlSearchParams.toString()}`;
			case "mock":
				return `${mockUrl}/users`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	}

	function getCalendarsEndpoint({ itemId }) {
		switch (environment) {
			case "dev":
				return `${baseUrl}/calendar/${proxyUrl}/booking/check?productId=${itemId}`
			case "prod":
				return `${baseUrl}/calendar/${proxyUrl}/booking/check?productId=${itemId}`
			case "sit":
				return `${baseUrl}/calendar/${proxyUrl}/booking/check?productId=${itemId}`
			case "mock":
				return `${mockUrl}/sessions`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	}

	function postAssignmentEndpoint(backofficeId) {
		switch (environment) {
			case "dev":
				return `${baseUrl}/asset/${proxyUrl}/secure/corporate/enrollment/fromHR`
			case "prod":
				return `${baseUrl}/asset/${proxyUrl}/secure/corporate/enrollment/fromHR`
			case "sit":
				return `${baseUrl}/asset/${proxyUrl}/secure/corporate/enrollment/fromHR`
			case "mock":
				return `${mockUrl}/assignements`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	}

	function getUploadRecordsEndpoint(itemsPerPage, backofficeId) {
		const userId = localStorage.getItem("UserId");
		
		switch (environment) {
			case "dev":
				return `${baseUrl}/etl/${proxyUrl}/s3/files?type=ANAG&items_per_page=${itemsPerPage}${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "prod":
				return `${baseUrl}/etl/${proxyUrl}/s3/files?type=ANAG&items_per_page=${itemsPerPage}${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "sit":
				return `${baseUrl}/etl/${proxyUrl}/s3/files?type=ANA&items_per_page=${itemsPerPage}${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "mock":
				return "http://localhost:3001/upload_records";
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	}
	function getEnrollmentHREndpoint(backofficeId) {
		switch (environment) {
			case "dev":
				return `${baseUrl}/asset/${proxyUrl}/secure/corporate/enrollment/fromHR?${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "prod":
				return `${baseUrl}/asset/${proxyUrl}/secure/corporate/enrollment/fromHR?${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "sit":
				return `${baseUrl}/asset/${proxyUrl}/secure/corporate/enrollment/fromHR?${Boolean(backofficeId) && backofficeId !== 'null' ? '&backofficeId=' + backofficeId : ''}`;
			case "mock":
				return "http://localhost:3001/upload_records";
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	}

	function getReportsEndpoint(type, managerId, backofficeId, itemsPerPage, nextPageToken) {
		switch (environment) {
			case "dev":
				return `${baseUrl}/report/${proxyUrl}/public/reports?report_type=${type}
					${Boolean(itemsPerPage) ? '&items=' + itemsPerPage : ''}${Boolean(nextPageToken) ? '&next_page=' + nextPageToken : ''}`;
			case "prod":
				return `${baseUrl}/report/${proxyUrl}/public/reports?report_type=${type}
					${Boolean(itemsPerPage) ? '&items=' + itemsPerPage : ''}${Boolean(nextPageToken) ? '&next_page=' + nextPageToken : ''}`;
				case "sit":
				return `${baseUrl}/report/${proxyUrl}/public/reports?report_type=${type}
					${Boolean(itemsPerPage) ? '&items=' + itemsPerPage : ''}${Boolean(nextPageToken) ? '&next_page=' + nextPageToken : ''}`;
			case "mock":
				return `${mockUrl}/reports`;
			default:
				throw new Error(`unknown environment ${environment}`);
		};
	}

	function getMonitoringEndpoint(){
		switch (environment) {
			case "dev":
				return 'https://prelios.sit.digitedacademy.net';
			case "prod":
				return 'https://prelios.digitedacademy.net'
			case "sit":
				return 'https://prelios.sit.digitedacademy.net';
			case "mock":
				//return `${mockUrl}/monitoring/?id=${tabName}`;
				return 'https://prelios.sit.digitedacademy.net';
			default:
				throw new Error(`unknown environment ${environment}`);
		};

	}

	function apiHost() {
	  switch (environment) {
		case "dev":
			return "https://s4b2apidev.ispfdevelop.com";
		case "prod":
			return "https://api.skills4business.it";
		case "sit":
			return "https://s4b2api.ispfdevelop.com";
		case "mock":
			return'';
		default:
			throw new Error(`unknown environment ${environment}`);
	  }
	}

	function preliosDomainUrl() {
		if (environment === 'prod') {
			return 'https://prelios.digitedacademy.net'
		} else {
			return 'https://prelios.sit.digitedacademy.net'
		}

	/*if (environment === 'sit') {
			console.log('qui')
			return 'https://prelios.sit.digitedacademy.net'
		} else {
			return ''
		}*/ //uncomment for testing in sit
	}

	return {
		getContentEndpoint,
		getContentsEndpoint,
		getOptionsEndpoint,
		getRequestsEndpoint,
		getSiteThemeEndpoint,
		getAllContentsEndpoint,
		getCatalogAssignEndpoint,
		getUserInfoEndpoint,
		getProductsEndpoint,
		getProductEndpoint,
		getOptionsEndpointSearch,
		getUsersEndpoint,
		getCalendarsEndpoint,
		postAssignmentEndpoint,
		getUploadRecordsEndpoint,
		getReportsEndpoint,
		getEnrollmentHREndpoint,
		apiHost,
		preliosDomainUrl,
		getMonitoringEndpoint
	};
};

export default useRoutes;
