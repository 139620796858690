export const ICONS_MAP = {
  "news": "NewspaperIcon",
  "learning_object": "VideoLibraryIcon",
  "live_event": "EventIcon",
  "training": "SchoolIcon",
  "collection": "CollectionsIcon",
  "selection": "CollectionsBookmarkIcon",
	"session":"CalendarInvitationIcon",
	"survey": "SurveyIcon",
	"questions":"QuestionIcon",
	"teacher":"PersonIcon",
	"calendar":"CalendarIcon",
	"product_manager":"PackageIcon",
	"media":"MediaIcon",
	"file":"FileIcon"
};
