import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
	alpha,
	Button,
	Grid,
	IconButton,
	Snackbar,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

import {styled} from '@mui/material/styles';
import { useQuery } from "@tanstack/react-query";

import useFetcher from "api/fetcher";
import useTheme from "api/siteTheme";
import useRoutes from "api/useRoutes";
import Icon from "components/Icon";
import InputField, {inputFileValidator} from "components/InputField";
import NoResults from "components/NoResults";
import Spinner from "components/Spinner";
import {FormikProvider, useFormik} from "formik";

import * as yup from "yup";
import useUpload from "@/api/uploader";


const StyledTableHeadCell = styled(TableCell)(({theme}) => ({
	[`&.${tableCellClasses.head}`]: {
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));


function Media() {
	
	const theme = useTheme();
	const [currentPage, setCurrentPage] = useState(0);
	const [open, setOpen] = useState(false);
	const pageSize = 10;
	// TODO: move react-query logic to custom api hook
	const fetcher = useFetcher();
	const {getContentsEndpoint} = useRoutes();
	useEffect(() => {
		setCurrentPage(0)
	}, [])
	const contentsEndpoint = getContentsEndpoint('image', currentPage, pageSize);
	
	const {data: contents, isLoading: loadingContents, refetch} = useQuery({
		queryKey: ['contents', 'image', currentPage],
		queryFn: () => fetcher(contentsEndpoint, {method: "GET"})
	});
	useEffect(() => {
		refetch()
	}, [currentPage]);
	
	document.body.addEventListener('click', (e) => {
		if (open && e.target.classList.contains('has-mask')) {
			document.body.classList.remove('has-mask')
			setOpen(false)
		}
	})
	const currentContents = contents?.data;
	const pages = contents?.pager.total_pages;
	
	const PopUpAddMedia = () => {
		const theme = useTheme();
		const [responseText, setResponseText] = useState('');
		const [responseType, setResponseType] = useState('');
		const [loading, setLoading] = useState(false);
		const [responseRequestPost, setResponseRequestPost] = useState(undefined);
		const urlPost = `/api/v1/content_manager/image`;
		const upload = useUpload();
		const navigate = useNavigate();
		
		
		const fieldType = {
			fieldName: "cover",
			fieldLabel: "immagine",
			apiEndpoint: "/api/v1/content_manager/image",
			fieldType: "file",
			fieldValue: "",
			required: false,
		}
		const contentFields = [
			{
				fieldName: "cover",
				fieldLabel: "immagine",
				apiEndpoint: "/api/v1/content_manager/image",
				fieldType: "file",
				fieldValue: "",
				required: true,
			}
		]
		
		useEffect(() => {
			
			if (Array.isArray(responseRequestPost)) {
				setResponseType('success');
				setResponseText(responseRequestPost?.[0]?.message);
				setTimeout(() => {
					navigate(-1)
				}, 600)
			} else if (!responseRequestPost) {
				
				setResponseType('');
			} else {
				setResponseType('error');
				setResponseText(responseRequestPost ? responseRequestPost.toString() : '');
			}
		}, [responseRequestPost])
		
		const formik = useFormik({
			initialValues: {
				cover: ''
			},
			validationSchema: getValidationSchema(),
			onSubmit: async (values, actions) => {
				await handleFormSubmit(contentFields, values);
				actions.setSubmitting(false)
			},
		});
		
		
		async function handleFileUpload(contentFields, values) {
			let payload = [];
			
			Object.values(contentFields)
				.filter((field) => (
					(field.fieldType === "file")
					&& Boolean(values[field.fieldName]?.name)
				))
				.map((element) => (
					payload.push(
						{
							field: element.fieldName,
							file: values[element.fieldName],
							endpoint: element.apiEndpoint,
							name: element.fieldName,
							ask_the_expert: element.fieldName === 'documents',
							method: 'POST',
						}
					)
				));

			if (!payload || payload.length === 0) {
				return values;
			} else {
				setLoading(true)
				const uploadResponse = upload(payload);
				const promiseResponse = await Promise.all(uploadResponse).then(fileValues =>  fileValues).catch(error => {
					console.log(error)
				})
				
				if (promiseResponse.length > 0) {
					setLoading(false)
					setResponseType('success')
					setResponseText(`L'immagine è stata caricata correttamente`)
					setTimeout(()=> {
						setOpen(false)
						document.body.classList.remove('has-mask')
					}, 800)
					promiseResponse.forEach(res => {
						values[res.field] = res.data.toString();
					})
					return values
					
				} else {
					setResponseType('error')
					setResponseText('qualcosa è andato storto')
					setTimeout(()=> {
						setOpen(false)
						document.body.classList.remove('has-mask')
					}, 800)
					return values
				}
			}
		}
		
		async function handleFormSubmit(contentFields, values) {
			let newValues = {...values};
			
			await handleFileUpload(
				contentFields,
				newValues,
			);

		};
		
		function getValidationSchema() {
			return yup.object(
				contentFields.reduce((acc, field) => ({
					...acc, ...inputFileValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					}),
				}), {})
			)
		};
		
		return (
			<Grid
				container
				justifyContent="space-between"
				flexWrap={'nowrap'}
				height={'100%'}
				className={open ? 'visible' : ''}
				sx={{
					display: 'inline-block',
					transform: 'scale(0)',
					opacity: '0',
					maxWidth: '600px',
					maxHeight: '300px',
					pointerEvents: 'none',
					position: 'fixed',
					top: '0',
					right: '0',
					bottom: '0',
					left: '0',
					margin: 'auto',
					borderRadius: '20px',
					boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 15px',
					transition: 'all .5s linear .2s',
					background: 'white',
					padding: '16px',
					zIndex: '5',
					'&.visible': {
						transform: 'scale(1)',
						pointerEvents: 'auto',
						opacity: '1'
					}
				}}
			>
				<Grid
					item
					xs={12}
					container
					justifyContent="center"
					height={'100%'}
				>
					<Grid
						item xs={12}
						align="center"
						component={Typography}
						variant="h3"
						gutterBottom
						textTransform="capitalize"
						padding={'20px 0'}
						color={theme.palette.primary.main}
						display={'flex'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						Immagine
						<Icon
							type="CloseIcon"
							color={theme.palette.primary.main}
							fontSize="large"
							onClick={(event, reason) => {
								if (reason === 'clickaway') {
									return;
								}
								setOpen(false);
								document.body.classList.remove('has-mask')
							}}
							sx={{cursor: 'pointer'}}
						/>
					</Grid>
					
					<FormikProvider value={formik}>
						
						<Grid
							component="form"
							item xs={12}
							onSubmit={(e) => {
								formik.handleSubmit();
								e.preventDefault()
							}}
						>
							
							{
								!loading ?
									responseType === '' ?
										<>
											<InputField field={fieldType} form={formik}
											            contentFields={contentFields}
											/>
											<Button
												fullwidth="true"
												type="submit"
												variant="contained"
												sx={{color: 'white', margin: '8px auto 0', display: 'flex'}}
											>
												salva
											</Button>
										</>
										
										:
										
										<Grid display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
											<Typography sx={{color: responseType === 'success' ? '#4caf50' : '#f44336', marginBottom:'10px' }}>{responseText}</Typography>
											<Icon type={responseType === 'success' ? 'DoneIcon' : 'CloseIcon' } sx={{color: responseType === 'success' ? '#4caf50' : '#f44336' }}/>
										</Grid>
									
									:
									
									<Spinner/>
							}
						
						
						</Grid>
					
					</FormikProvider>
				</Grid>
			
			</Grid>
		)
	}
	
	return (
		<Grid container sx={{
			flexDirection: "column",
			flexWrap: "nowrap",
			height: "85vh",
			padding: 4,
			width: '100%'
		}}>
			<PopUpAddMedia />
			
			<Grid item container
			      alignItems="center"
			      justifyContent="space-between"
			      marginBottom={5}
			
			>
				<Typography
					sx={{
						color: theme.palette.primary.main,
						textTransform: "capitalize",
					}}
					variant="h3"
				>
					Immagini
				</Typography>
				
				<Button variant="outlined"
				        size="large"
				        sx={{
					        color: `${theme.palette.primary.main}`,
					        borderColor: `${theme.palette.primary.main}`,
					        '&:hover': {
						        borderColor: `${theme.palette.primary.main}`,
						        backgroundColor: alpha(theme.palette.primary.main || '#40dadc', 0.1)
					        }
				        }}
				        startIcon={
					        <Icon
						        type="AddIcon"
						        color={theme.palette.primary.main}
						        fontSize="large"
					        />
				        }
				        onClick={() => {
					        setOpen(true)
					        document.body.classList.add('has-mask')
					        
				        }}
				>
					Aggiungi
				</Button>
			</Grid>
			<Grid
				item
				sx={{minHeight: "100%", overflowX: 'auto', height: '100%'}}
			>
				{loadingContents ? (
					<Spinner/>
				) : (
					Array.isArray(currentContents) && currentContents.length > 0 ? (
						<Table>
							<TableHead sx={{backgroundColor: `${theme.palette.primary.main}`}}>
								<TableRow sx={{'& td, & th': {border: 0,}}}>
									<StyledTableHeadCell>
										Id
									</StyledTableHeadCell>
									<StyledTableHeadCell>
										Preview
									</StyledTableHeadCell>
									<StyledTableHeadCell>
										Titolo
									</StyledTableHeadCell>
									<TableCell/>
									
									<TableCell/>
									<TableCell/>
									<TableCell/>
								</TableRow>
							</TableHead>
							<TableBody sx={{textTransform: "capitalize"}}>
								{currentContents.map((content) => (
									<TableRow
										key={`content--${content.id || content.name}`}
										sx={{
											boxShadow: 1,
											"& .MuiTableCell-body": {paddingY: 3},
											'& td, & th': {border: 0},
										}}
									>
										<TableCell sx={{width: '30px'}}>
											<Typography>
												{content?.id || "No ID"}
											</Typography>
										</TableCell>
										<TableCell sx={{width: '200px', height: '100px'}}>
											<img src={content.uri} style={{width: '100%', maxHeight: '100%', objectFit: 'contain'}}/>
										</TableCell>
										<TableCell>
											<Typography sx={{fontWeight: 400}}>
												{content?.name || "No title"}
											</Typography>
										</TableCell>
										<TableCell/>
										
										<TableCell/>
										<TableCell/>
										<TableCell/>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								<TableRow sx={{
									'& td, & th': {
										border: 0,
									},
								}}>
									<TableCell>
										<Typography>pagina <strong>{currentPage + 1}</strong></Typography>
									</TableCell>
									<TableCell/>
									
									<TableCell/>
									<TableCell/>
									<TableCell/>
									<TableCell/>
									<TableCell sx={{
										display: "flex",
										justifyContent: "center"
									}}>
										<IconButton
											sx={{
												'&.Mui-disabled': {
													'.MuiSvgIcon-root': {
														cursor: 'not-allowed',
														pointerEvents: 'auto'
													}
												}
											}}
											disabled={currentPage + 1 <= 1}
											onClick={() => setCurrentPage(prev =>
												Math.min(prev - 1)
											)}
										>
											<Icon type="KeyboardDoubleArrowLeftIcon"/>
										</IconButton>
										<IconButton
											sx={{
												'&.Mui-disabled': {
													'.MuiSvgIcon-root': {
														cursor: 'not-allowed',
														pointerEvents: 'auto'
													}
												}
											}}
											disabled={!currentContents || currentPage + 1 >= pages}
											onClick={(e) => {
												setCurrentPage(prev =>
													prev + 1
												)
											}
											}
										>
											<Icon type="KeyboardDoubleArrowRightIcon"/>
										</IconButton>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					) : (
						<NoResults/>
					)
				)}
			</Grid>
		</Grid>
	);
}


export default Media;
