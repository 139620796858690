import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useFetcher from "api/fetcher";
import useContentType from "api/useContentType";
import useRoutes from "api/useRoutes";
import Spinner from "components/Spinner";
import ContentsForm from "routes/ContentsForm";

function ContentsFormEdit() {
  const contentType = useContentType();
  const { id } = useParams();
  // TODO: move react-query logic to custom api hook
  const fetcher = useFetcher()
	
  const { getContentEndpoint, getProductEndpoint } = useRoutes();
  const contentEndpoint = contentType === 'product_manager' ?  getProductEndpoint(contentType, id) : getContentEndpoint(contentType, id);

  const { data: content, isLoading, refetch} = useQuery({
    queryKey: ["contents", contentType, id],
    queryFn: () => fetcher(contentEndpoint, { method: "GET" }),
  });

  useEffect(() => {
		refetch();
	}, [content])

  return (
    isLoading
      ? <Spinner />
      : <ContentsForm content={content?.data} id={id}/>
  );
};

export default ContentsFormEdit;