import React, {useEffect, useReducer, useRef, useState} from "react";
import {
	Grid,
	Typography,
} from "@mui/material";
import Tab from "components/Tab";
import Icon from "components/Icon";

import PlatformContent from "./Platform";
import LearningObjectContent from "./LearningObject";
import { useQuery } from "@tanstack/react-query";
import { FETCHER_MODE } from "@/utils/FetcherModes";
import useFetcher from "@/api/fetcher";
import useRoutes from "@/api/useRoutes";
import Spinner from "@/components/Spinner";
import {MONITORING_REPORTS} from "@/routes/Monitoring/monitoringActions";

const getPathByChart = (tabIndex, cardIndex) => {
	if (tabIndex === 0){ // piattaforma
		switch (cardIndex){
			case 0: // stabilità/fruibilità
				return '/SLA_Stabilita-Piattaforma/OUTPUT/fruibilita.csv'; // stabilità della piattaforma
			case 1:
				return '/SLA_Helpdesk/OUTPUT/presa_in_carico.csv'; // presa in carico della segnalazione
			case 2:
				return '/SLA_Helpdesk/OUTPUT/risoluzione.csv'; // risoluzione della segnalazione
			default:
				console.error(`Card selezionata non valida. TabIndex ${tabIndex}, CardIndex ${cardIndex}`);
		}
	}else{ // learning object
		switch (cardIndex){
			case 0:
				return '/SLA_Consegna-LO/OUTPUT/delta_working_days.csv' // consegna learning object
			case 1:
				return '/SLA_Qualita-LO/OUTPUT/qualita.csv'; // qualità learning object
			default:
				console.error(`Card selezionata non valida. TabIndex ${tabIndex}, CardIndex ${cardIndex}`);
		}
	}
	return '';
}

const changeYearInState = (isNext, payload, prevState)=>{
	let updatedState = prevState.map((tab, tabIndex) => {
		if (tabIndex === payload.tabIndex){
			// replace card da aggiornare
			return {...tab, cards: tab.cards.map((card, cardIndex) => {
				if (cardIndex === payload.cardIndex){
					return {...card, kpis: card.kpis.map((kpi, kpiIndex) => {
						if (kpiIndex === payload.kpiIndex){
							let changedYearIndex = isNext ? kpi.selectedYearIndex + 1 : kpi.selectedYearIndex - 1;
							let valYear = kpi.yearsArray[changedYearIndex];
							let countTruthy = kpi.dataset.value[valYear].filter(el => !!el.value || (el.value === 0)).length;
							let total = kpi.dataset.value[valYear].reduce((prev, current) => {
								return prev+current.value;
							},0)
							return {...kpi, average: (total/countTruthy).toFixed(2), selectedYearIndex: changedYearIndex}
						}
						return kpi;
					})}
				}
				return card;
				})}
		}
		return tab;
	})
	return updatedState;
}

const tabsReducer = (state, action)=>{
	const { type, payload } = action;

	switch (type){
		case MONITORING_REPORTS.PREV_YEAR: {
			return changeYearInState(false,payload, state)
		}
		case MONITORING_REPORTS.NEXT_YEAR: {
			return changeYearInState(true,payload, state)
		}
		case MONITORING_REPORTS.SET_CARD_DATASET: {
			let updatedState = [...state];
			updatedState[payload.tabIndex].cards = state[payload.tabIndex].cards.map((card,index) => {
				if (payload.cardIndex === index){
					let updatedCard = {...card};
					updatedCard.kpis = [...card.kpis];
					payload.dataArray.forEach((data, kpiIndex)=>{
						updatedCard.kpis[kpiIndex] = {...card.kpis[kpiIndex], dataset: payload.dataArray[kpiIndex]};
					})

					// calc average and get last year
					updatedCard.kpis.forEach((data, kpiIndex)=>{
						let total = 0;
						let years = Object.keys(data.dataset.value);
						updatedCard.kpis[kpiIndex].selectedYearIndex = years.length > 0 ? years.length-1 : null; // select last available year
						updatedCard.kpis[kpiIndex].yearsArray = years;
						let lastYear = years[updatedCard.kpis[kpiIndex].selectedYearIndex];
						if (lastYear){
							total = data.dataset.value[lastYear].reduce((prev, current) => {
								return prev+current.value;
							},0)
						}
						let countTruthy = data.dataset.value[lastYear].filter(el => !!el.value || (el.value === 0)).length;
						updatedCard.kpis[kpiIndex].average = (total/countTruthy).toFixed(2);
					})
					return updatedCard;
				}else{
					return card;
				}
			})
			return updatedState;
		}
		default:
			return state;
	}
}

function Monitoring() {

	const getCardKey = (tabIndex, cardIndex) => {
		if (tabIndex === 0) {
			switch (cardIndex) {
				case 0: {
					return 'fruibilita'
				}
				case 1: {
					return 'presaInCarico'
				}
				case 2: {
					return 'risoluzioneSegnalazione'
				}
			}
		} else {
			switch (cardIndex) {
				case 0: {
					return 'consegna'
				}
				case 1: {
					return 'qualita'
				}
			}
		}

	}
	const [currentTab, setCurrentTab] = useState(0);
	const [activeCardIndex, setActiveCardIndex] = useState(0);

	const { getMonitoringEndpoint } = useRoutes();
	const monitoringEndpoint = getMonitoringEndpoint();

	const [tabs,dispatchTabs] = useReducer(tabsReducer,[
		{
			title: "PIATTAFORMA",
			iconType: "MonitoringPlatform",
			width: 10,
			cards: [
				{
					title: "Stabilità della piattaforma",
					kpis: [{"title":"FRUIBILITÀ DELLA PIATTAFORMA", "average":0, "dataset":null, "selectedYearIndex":null, "yearsArray":[], "color":"#4da6b1"}]
				},
				{
					title: "Presa in carico della segnalazione",
					kpis: [{"title":"PRESA IN CARICO INACESSIBILITÀ","average":0, "dataset":null, "selectedYearIndex":null, "yearsArray":[], "color":"#0d20c0"}, {"title":"PRESA IN CARICO MALFUNZIONAMENTO SPECIFICO", "average":0, "dataset":null, "selectedYearIndex":null, "yearsArray":[], "color":"#8c33e8"}]
				},
				{
					title: "Risoluzione della segnalazione",
					kpis: [{"title":"RISOLUZIONE INACESSIBILITÀ", "average":0, "dataset":null, "selectedYearIndex":null, "yearsArray":[], "color":"#3470ae"},{"title": "RISOLUZIONE MALFUNZIONAMENTO SPECIFICO", "average":0, "dataset":null, "selectedYearIndex":null, "yearsArray":[],"color":"#d48b33"}]
				}
			]
		},{
			title: "LEARNING OBJECT",
			iconType: "MonitoringLO",
			width: 10,
			cards: [
				{
					title: "Consegna Learning Object",
					kpis: [{"title":"CONSEGNA LO", "average":0, "dataset":null, "selectedYearIndex":null, "yearsArray":[],"color":"#4da6b1"}]
				},
				{
					title: "Qualità Learning Object",
					kpis: [{"title":"KPI 1", "average":0, "dataset":null, "selectedYearIndex":null, "yearsArray":[],"color":"#0d20c0"}, {"title":"KPI 2", "average":0, "dataset":null, "selectedYearIndex":null, "yearsArray":[],"color":"#8c33e8"}]
				}
			]
		}
	])



	const fetcher = useFetcher();
	const { data: allReports, isFetching: loadingContents, refetch } = useQuery({
		queryKey: ["monitoring"],
		enabled: false,
		queryFn: async () => {
			let res1 = await fetcher(FETCHER_MODE.PUBLIC_S3)(
				monitoringEndpoint,
				{ method: "GET", filePath: getPathByChart(currentTab, 0) }
			)
			let dataArray = [];
			if (getCardKey(currentTab, 0) === 'consegna') {
				dataArray = getConsegnaLoArray(res1);
			}else{
				dataArray = getFinalDataArray(res1, currentTab, 0);
			}
			if(res1.indexOf("<!doctype") < 0){
				dispatchTabs({ type: MONITORING_REPORTS.SET_CARD_DATASET, payload: { tabIndex: currentTab, cardIndex: 0, dataArray: dataArray } });
			}

			let res2 = await fetcher(FETCHER_MODE.PUBLIC_S3)(
				monitoringEndpoint,
				{ method: "GET", filePath: getPathByChart(currentTab, 1) }
			)
			if(res2.indexOf("<!doctype") < 0){
				dataArray = getFinalDataArray(res2,currentTab, 1);
				dispatchTabs({ type: MONITORING_REPORTS.SET_CARD_DATASET, payload: { tabIndex: currentTab, cardIndex: 1, dataArray: dataArray } });
			}


			let res3 = null;
			if (currentTab === 0){ // terza card disponibile solo per il primo tab
				res3 = await fetcher(FETCHER_MODE.PUBLIC_S3)(
					monitoringEndpoint,
					{ method: "GET", filePath: getPathByChart(currentTab, 2) }
				)
				if(res3.indexOf("<!doctype") < 0){
					dataArray = getFinalDataArray(res3,currentTab, 2);
					dispatchTabs({ type: MONITORING_REPORTS.SET_CARD_DATASET, payload: { tabIndex: currentTab, cardIndex: 2, dataArray: dataArray } });
				}
			}

			return res3 ? [res1, res2, res3] : [res1, res2];
		},
	});

	const isNil = (value) => {
		return !value && (value !== 0) && (value !== '');
	}

	// Funzione per convertire da secondi a giorni
	function secondsToDays(seconds) {
		const days = seconds / (24 * 60 * 60);
		return days;
	}

	// Funzione per convertire da secondi a ore
	function secondsToHours(seconds) {
		const hours = seconds / (60 * 60);
		return hours;
	}

	// Funzione per convertire da decimale a percentuale
	function decimalToPercentage(decimal) {
		const percentage = decimal * 100;
		return percentage;
	}

	const convertData = (value, tabIndex, cardIndex) => {
		if (getCardKey(tabIndex, cardIndex) == 'fruibilita' || getCardKey(tabIndex, cardIndex) == 'qualita') {
			return decimalToPercentage(value);
		}
		if (getCardKey(tabIndex, cardIndex) == 'presaInCarico') {
			return secondsToHours(value);
		}
		if (getCardKey(tabIndex, cardIndex) == 'qualita' || getCardKey(tabIndex, cardIndex) == 'risoluzioneSegnalazione') {
			return secondsToDays(value);
		}
		return value;
	}

	const getConsegnaLoArray = (reports)=>{
		if (reports) {
			const lines = reports.split('\n').filter(item => item.trim());

			const datasetArray = [{
				"title": "Consegna Learning Object",
				"value": {},
				"type": "gg",
				"isDynamicLabels": true
			}];
			let allYears = {};
			lines.slice(1).forEach(line => {
				allYears = {};
				const values = line.split(',').map(item => item.trim());
				const dataPubblicazionePrevista = values[0];
				//const dataPubblicazioneEffettiva = values[1];
				const year = dataPubblicazionePrevista.split('-')[0];
				allYears[year] = year;
				const days = parseInt(values[2]);
				if (!datasetArray[0].value[year]) {
					datasetArray[0].value[year] = []
				}
				datasetArray[0].value[year].push({"date": dataPubblicazionePrevista, "value": days});
			})
			return datasetArray
		}
		return []
	}
	// Funzione di utilità per verificare l'uguaglianza tra due array
	const arraysEqual = (arr1, arr2) => {
		return JSON.stringify(arr1) === JSON.stringify(arr2);
	};

	const getFinalDataArray = (reports, tabIndex, cardIndex)=>{
		const finalDataArray = [];
		if (reports && reports.length){
			const lines = reports.split('\n').filter(item => item.trim());
			const header = lines[0].split(',').map(item => item.trim());
			// Analizza le linee e raggruppa i dati
			lines.slice(1).forEach(line => {
				const values = line.split(',').map(item => item.trim());
				const entry = {};
				header.forEach((key, index) => {
					entry[key] = values[index];
				});

				// Itera sulle colonne dopo "Mese" e raggruppa i dati
				header.slice(header.indexOf('Mese') + 1).forEach(column => {
					// Trova l'elemento corrente nell'array finalDataArray
					let currentElement = finalDataArray.find(item => item.title === column);
					// Se l'elemento non esiste, crealo e aggiungilo all'array
					if (!currentElement) {
						currentElement = {
							title: column,
							value: {},
							type: getCardKey(tabIndex, cardIndex) == 'fruibilita' || getCardKey(tabIndex, cardIndex) == 'qualita' ? '%' : getCardKey(tabIndex, cardIndex) == 'presaInCarico' ? 'h' : 'gg', // Sostituisci con il tipo appropriato
						};
						finalDataArray.push(currentElement);
					}
					// Se l'anno non esiste ancora nell'array value, crealo
					if (!currentElement.value[entry.Anno]) {
						currentElement.value[entry.Anno] = [];
					}

					let convertedData = convertData(entry[column], tabIndex, cardIndex);
					// Aggiungi l'entry all'array della colonna e anno correnti
					currentElement.value[entry.Anno].push({ month: entry.Mese, value: convertData(entry[column], tabIndex, cardIndex) });
				});
			});
		}
		finalDataArray.forEach(item => {
			Object.keys(item.value).forEach(year => {
				const expectedMonths = Array.from({ length: 12 }, (_, i) => i + 1);
				const actualMonths = item.value[year].map(entry => entry.month).sort((a, b) => a - b);

				// Se il numero di mesi non corrisponde, aggiungi gli elementi mancanti con value vuoto
				if (!arraysEqual(expectedMonths, actualMonths)) {
					expectedMonths.forEach(month => {
						const existingIndex = item.value[year].findIndex(entry => entry.month == month);
						if (existingIndex === -1) {
							item.value[year].push({ month, value: null });
						}
					});

					// Ordina gli elementi in base al numero del mese
					item.value[year] = item.value[year].sort((a, b) => a.month - b.month);
				}
			});
		});
		return finalDataArray;
	}


	const setCardIndexHandler = (index) => {
		setActiveCardIndex(index);
	}

	useEffect(() => {
		if (!isNil(currentTab)){
			refetch();
			setActiveCardIndex(0); // quando cambio tab, riporto cardIndex a 0
		}
	}, [currentTab])


	const prevYearHandler = (kpiIndex)=>{
		dispatchTabs({type: MONITORING_REPORTS.PREV_YEAR, payload: {tabIndex: currentTab, cardIndex: activeCardIndex, kpiIndex: kpiIndex}})
	}
	const nextYearHandler = (kpiIndex)=>{
		dispatchTabs({type: MONITORING_REPORTS.NEXT_YEAR, payload: {tabIndex: currentTab, cardIndex: activeCardIndex, kpiIndex: kpiIndex}})
	}
	return (
		<Grid
			container
			spacing={2}
			padding={5}
		>
			<Grid item container
				alignItems="center"
				justifyContent="space-between"
				marginBottom={5}
			>
				<Typography
					fontSize={25}
					fontWeight={600}
					color={'rgb(81, 81, 81, 1)'}
				>
					{'Monitoraggio KPI'}
				</Typography>
			</Grid>
			{tabs && <Grid display={'flex'} width={'100%'} justifyContent={'space-between'} marginBottom={'20px'}
						   columnGap={'10px'}>
				{
					tabs.map((tab, index) => (
						<Tab key={`tab_${index}`} onClick={() => {
							setCurrentTab(index);
							setCardIndexHandler(index)
						}} title={tab.title} isActive={currentTab === index}
							 icon={<Icon type={tab.iconType} width={tab.width}/>}></Tab>
					))
				}
			</Grid>}
			{(loadingContents || !tabs) ? <Spinner /> : currentTab === 0 ? <PlatformContent dataset={tabs[0]} activeCardIndex={activeCardIndex} prevYear={prevYearHandler} nextYear={nextYearHandler} setActiveCardIndex={setCardIndexHandler} /> : <LearningObjectContent dataset={tabs[1]} activeCardIndex={activeCardIndex} prevYear={prevYearHandler} nextYear={nextYearHandler} setActiveCardIndex={setCardIndexHandler}  />}
		</Grid>
	)
}

export default Monitoring;