import React, {useState, useEffect} from "react";
import {
	FormControl,
	FormGroup,
	FormLabel,
} from "@mui/material";
import {styled} from '@mui/material/styles';
import {Editor} from "react-draft-wysiwyg";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useTheme from "api/siteTheme";
import {resetGroupFields} from "components/InputField";

import {useQueryClient} from '@tanstack/react-query'

const StyledFormGroup = styled(FormGroup)(() => ({
	border: `1px solid #d9d9d9`,
	borderRadius: "4px",
	padding: 4,
}));

function InputHTML({
	                   contentFields,
	                   controlForm,
	                   controlGroup,
	                   controlLabel,
	                   controlName,
	                   required,
	                   id,
	                   contentType
                   }) {
	const theme = useTheme();
	
	const queryClient = useQueryClient();
	const data = queryClient.getQueryData(['contents', contentType, id]);
	
	
	const [editorState, setEditorState] = useState(
		() =>
			EditorState.createWithContent(
				ContentState.createFromBlockArray(
					convertFromHTML(data?.data?.[0][controlName] || ""), ''
				)
			)
	);
	
	
	return (
		<FormControl>
			<FormLabel
				component="legend"
				sx={{
					color: `${theme.palette.primary.main}`,
					padding: '0 0.8rem',
				}}
			>
				{`${controlLabel}${required ? '*' : ''}`}
			</FormLabel>
			<StyledFormGroup>
				<Editor
					id={controlName}
					name={controlName}
					label={controlLabel}
					editorState={editorState}
					onEditorStateChange={onChange}
				/>
			</StyledFormGroup>
		</FormControl>
	);
	
	function onChange(newEditorState) {
		// set draft-js
		setEditorState(newEditorState)
		// set formik
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		controlForm.setFieldValue(
			controlName,
			draftToHtml(convertToRaw(newEditorState.getCurrentContent()))
		)
		controlForm.setTouched({[controlName]: true}, true)
	}
};

// No validation , LOL

export default InputHTML;
