export const RECORDS_FIELDS = {
	contentType:'Carica anagrafiche',
	fields:
[
	{
		fieldName: "file",
		fieldLabel: "carica file",
		fieldType: "file",
		fieldValue: "",
		required: true,
	}
]

}