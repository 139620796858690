import React from "react";
import {
  Box,
  CircularProgress,
} from "@mui/material";

function Spinner() {
  return (
    <Box sx={{
      alignItems: "center",
      display: "flex",
      flexGrow: 1,
      justifyContent: "center",
      height: "100%"
    }}>
      <CircularProgress size={80}/>
    </Box>
  );
};

export default Spinner;