import styled from 'styled-components';

function Tab({ title, icon, isActive, onClick }) {


    const TabContainer = styled.div`
    cursor: pointer;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    text-align:center;
    position:relative;
    color: ${isActive == true ? '#fff' : '#515151'} ;
    background-color: ${isActive == true ? '#00AAB4' : '#E0E0E0'} ;
  `;

    const IconContainer = styled.div`
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    left:50%;
    padding:10px 15px 0px 15px;
    top:-5px;
    transform: translate(-50%, -50%);
    position:absolute;
    color: ${isActive == true ? '#fff' : '#515151'} ;
    background-color: ${isActive == true ? '#00AAB4' : '#E0E0E0'} ;
`;

    return (
        <TabContainer isActive onClick={() => onClick()}>
            <IconContainer>
                {icon}
            </IconContainer>
            <div style={{ fontSize: 16, fontWeight: 700 }}>
                {title}
            </div>
        </TabContainer>
    );
}

export default Tab;