import React from "react";
import {
	Box,
	FormControl,
	FormHelperText,
	Input,
	Typography,
} from "@mui/material";
import * as yup from "yup";
import useTheme from "api/siteTheme";
import {resetGroupFields} from "components/InputField";

function ImportFile({
	                    contentFields,
	                    controlForm,
	                    controlGroup,
	                    controlLabel,
	                    controlName,
	                    required
                    }) {
	const theme = useTheme();

	function isObject(value) {
		return (
			typeof value === 'object' &&
			value !== null &&
			!Array.isArray(value)
		);
	}
	
	return (
		<FormControl fullWidth>
			<Box>
				<Typography
					marginX={3}
					sx={{
						color: `${theme.palette.primary.main}`,
						fontSize: '0.75rem',
						margin: '0 0 4px 0',
					}}
				>
					{`${controlLabel}${required ? '*' : ''}`}
					{controlForm.values[controlName] ? (
						<span>File selezionato : {
							isObject(controlForm.values[controlName])
								? controlForm.values[controlName].name 
								: controlForm.values[controlName]
						}
						</span>
					) : null}
				</Typography>
				<Input
					sx={{
						alignItems: "center",
						border: 1,
						borderColor: "#d9d9d9",
						borderRadius: "4px",
						display: "flex",
						paddingY: 2,
						justifyContent: "space-between",
						width: "100%",
						padding: '9px',
						color: '#666666',
						'input': {
							'&::-webkit-file-upload-button': {
								background: 'rgb(51, 167, 178)',
								border: 'none',
								borderRadius: '4px',
								boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
								marginRight: '10px',
								cursor: 'pointer',
								transition: 'all .1s linear',
								color: 'white',
								'&:hover': {
									backgroundColor: 'rgb(35, 116, 124)',
									boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;'
								}
							}
						}
					}}
					id={controlName}
					name={controlName}
					label={controlLabel}
					disableUnderline
					type="file"
					accept="image/*"
					onChange={(event) => {
						event.preventDefault();
						handleChange(event)
					}}
					error={controlForm.touched[controlName]
						&& Boolean(controlForm.errors[controlName])}
				/>
			</Box>
			<FormHelperText
				id={controlName}
				error={controlForm.touched[controlName]
					&& Boolean(controlForm.errors[controlName])}
			>
				{controlForm.touched[controlName]
					&& controlForm.errors[controlName]}
			</FormHelperText>
		</FormControl>
	);
	
	function handleChange(event) {
		
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		controlForm.setFieldValue(
			controlName,
			event.currentTarget.files[0],
			true,
		);
		controlForm.setTouched(
			{[controlName]: true},
			true
		);
	};
};

export const inputFileValidator = ({
	                                   controlLabel,
	                                   controlName,
	                                   controlIsRequired
                                   }) => {
	const validator = {
		[controlName]: yup
			.mixed()
			.nullable().notRequired()
			.test({
				message: controlName === 'file' ? `${controlLabel} deve essere un file` :
					`${controlLabel} deve essere un'immagine`,
				test: (file, context) => {
					if (file?.type !== undefined && file !== undefined) {
						const isValid = controlName === 'file' ? [
							'application/vnd.ms-excel',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							'application/rtf',
							'text/plain',
							'application/pdf',
							'application/msword',
							'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
							'application/vnd.ms-powerpoint',
							'application/vnd.openxmlformats-officedocument.presentationml.presentation',
							'application/vnd.ms-excel',
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							'application/pdf',
							'application/vnd.oasis.opendocument.text',
							'application/vnd.oasis.opendocument.presentation',
							'application/vnd.oasis.opendocument.spreadsheet',
						] : [
							'image/jpeg',
							'image/jpg',
							'image/png',
							'image/svg+xml'
						].includes(file?.type);
						if (!isValid) {
							const type = file?.type?.match(/(?!\/)[0-9A-Za-z]+$/)[0]
							return context?.createError({
								message: `impossibile caricare un formato di tipo ${type} `
							})
						}
						return true;
					} else {
						return true
					}
					
				},
			})
			.test({
				message: "Necessario file inferiore a 10MB",
				test: (file) => {
					if (!file || file?.type === undefined) {
						return true;
					} else {
						return file?.size < 10_000_000 // less than 10MB
					}
					
				},
			})
	};
	if (controlIsRequired) {
		validator[controlName] =
			validator[controlName]
				.required(`${controlLabel} è obbligatorio`)
	}
	return validator;
};

export default ImportFile;
