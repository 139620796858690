import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {
	Button,
	Card,
	Grid,
	CardActions,
	Stack,
	Typography,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableHead,
	TableRow,
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {styled} from '@mui/material/styles';
import useTheme from "api/siteTheme";
import useGetTheme from "api/siteTheme";
import useFetcher from 'api/fetcher';
import useRoutes from 'api/useRoutes';
import { FETCHER_MODE } from 'utils/FetcherModes';
import Spinner from "components/Spinner";
import { useQuery } from "@tanstack/react-query";
import ReportTable from 'components/ReportTable';

function Reports() {

  const [type, setType] = React.useState();
const theme = useTheme();

  const handleChange = (event) => {
    setType(event.target.value);

  };

	return (
		<Grid
			container
			spacing={2}
			padding={5}
		>
		<Grid item container
        			      alignItems="center"
        			      justifyContent="space-between"
        			      marginBottom={5}

        			>
        				<Typography
        					sx={{
        						color: `${theme.palette.primary.main}`,
        						textTransform: "capitalize",
        					}}
        					variant="h3"
        				>
        					{'Reportistica'}
        				</Typography>
            </Grid>
            <FormControl fullWidth sx={{
                                   				marginBottom: '50px',
                                   				}}>
                <InputLabel id="type-select-label">Tipologia</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={type}
                  label="Tiplogia"
                  onChange={(e)=>{
                    setType(e.target.value)
                  }}
                >
                  <MenuItem value={'ASSET_FRUITIONS'}>Fruizioni per contenuto</MenuItem>
                  <MenuItem value={'TOTAL_FRUITIONS'}>Fruizioni totali</MenuItem>
				  <MenuItem value={'LO_FRUITIONS'}>Fruizioni singoli video</MenuItem>
				  <MenuItem value={'REVIEWS'}>Recensioni</MenuItem>
				  <MenuItem value={'EF_REPORT'}>Report corsi inglese</MenuItem>
                </Select>
              </FormControl>

              {type ?
                     (<ReportTable type={type}/>)
              : 'Seleziona tipologia'}
		</Grid>
	)
};

export default Reports;