import React, {useState, useEffect} from "react";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormHelperText
} from "@mui/material";
import useTheme from "api/siteTheme";
import {resetGroupFields} from "components/InputField";


// https://mui.com/material-ui/react-checkbox/#formgroup
function InputCheckbox({
	                       contentFields,
	                       controlForm,
	                       controlGroup,
	                       controlLabel,
	                       controlName,
	                       isExclusive = false,
	                       exclusiveSubFields,
	                       contentType,
	                       isCreating,
	                       required
                       }) {
	const theme = useTheme();
	const [errorMessage, setErrorMessage] = useState('');
	const [isDisabled, setIsDisabled] = useState(false);

	const [fieldVisible, setFieldVisible] = useState();

	useEffect(() => {
		if (controlName === 'has_comment') {
			if (controlForm.values.question_type !== 'question_evaluation') {
				setFieldVisible(false);
			} else {
				setFieldVisible(true);
			}
		} else {
			setFieldVisible(true);
		}
	}, [controlForm])
	
	const detectDisabled = () => {
		switch (true) {
			case controlName === 'new_hired':
				if (isCreating) {
					if (Boolean(controlForm.values?.roles.length > 0) || Boolean(controlForm.values?.levels.length > 0)) {
						setErrorMessage('non possono essere inseriti contemporaneamente  roles levels e new hired')
						return true
					} else {
						setErrorMessage('')
						return false
					}
				} else if (contentType === 'product_manager') {
					setErrorMessage(`${controlName} non può essere modificato`)
					return true
				} else {
					setErrorMessage('')
					return false
				}
				case controlName === 'published':
				if (contentType === 'selection' || contentType === 'collection') {
					if (!Boolean(controlForm.values?.content.length > 0)) {
						setErrorMessage(`${contentType} non può essere pubblicata se il campo ${contentType === 'selection' ? 'Training associati' : 'LO'}  non è popolato`)
					} else {
						setErrorMessage('')
					}
					return !Boolean(controlForm.values?.content.length > 0)
				} else {
					setErrorMessage('')
					return false
				}
			default:
				setErrorMessage('')
				return false
			
		}
	}
	
	useEffect(() => {
		setIsDisabled(detectDisabled());
	}, [controlForm]);
	
	
	return (
		<>
			{fieldVisible && <FormControl>
				{
					Boolean(errorMessage) ? (
						<FormHelperText
							sx={{
								color: `${theme.palette.primary.main}`,
								margin: '0px'
							}}
						>
							{errorMessage}
						</FormHelperText>
					)	: null
				}
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								id={controlName}
								name={controlName}
								label={`${controlLabel}${required ? '*':''}`}
								inputProps={{'aria-label': controlName}}
								size="large"
								checked={isDisabled ? !isDisabled : Boolean(controlForm.values[controlName])}
								onChange={handleChange}
								disabled={isDisabled}
							/>
						}
						label={controlLabel}
					/>
				</FormGroup>
			</FormControl>}
		</>
	);
	
	function handleChange(event, checked) {
		if (isExclusive && checked === false) {
			exclusiveSubFields.forEach((subField) => {
				controlForm.setFieldValue(
					subField.fieldName, // field
					controlForm.initialValues[subField.fieldName],//value
					true, //shouldValidate
				)
			})
		}
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		controlForm.handleChange(event, checked)
	}
};

export default InputCheckbox;
