import React from "react";
import {Link} from "react-router-dom";
import {
	Button,
	Card,
	Grid,
	CardActions,
	// CardContent,
	// Paper,
	Stack,
	// Table,
	// TableBody,
	// TableCell,
	// tableCellClasses,
	// TableContainer,
	// TableHead,
	// TableRow,
	Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
// import {styled} from '@mui/material/styles';
import {CONTENT_TYPES} from "api/config";
import useTheme from "api/siteTheme";
import Icon from "components/Icon";
import {ICONS_MAP} from "utils/IconsMap";

function DasboardHome() {
	
	const contentTypes = CONTENT_TYPES;

	return (
		<Grid
			container
			spacing={2}
			padding={5}
		>
			{Array.isArray(contentTypes) && contentTypes.map((contentType) => {
				const content = contentType.id.replace(/\s/, '-')
				return (
					<CardHome
						key={content}
						contentTypeLink={contentType.id}
						contentTypeTitle={contentType.title}
					/>
				)
			})}
		</Grid>
	)
};

function CardHome({contentTypeLink, contentTypeTitle}) {
	const theme = useTheme();
	return (
		<Grid item xs={12} sm={6} md={4}>
			<Card sx={{
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				boxShadow: 'rgba(0, 0, 0, 0.2) -1px -1px 0px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;',
				'&:hover': {
					boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 15px'
				}
			}}>
				
				<Stack
					alignItems="flex-start"
					direction="row"
					justifyContent="space-between"
					spacing={3}
					padding="16px"
				>
					<Typography variant="h4" sx={{color: `${theme.palette.primary.main}`}}>
						{contentTypeTitle}
					</Typography>
					<Icon
						sx={{color: `${theme.palette.primary.main}`}}
						fontSize="large"
						type={ICONS_MAP[contentTypeLink]}
					/>
				</Stack>
				<CardActions sx={{
					padding: '16px',
					justifyContent: 'space-between'
				}}>
					<Link
						to={contentTypeLink.replace(/\s/, '-')}
						style={{textDecoration: "none", color: `${theme.palette.primary.main}`}}
					>
						<Button
							variant="outlined"
							size="small"
							sx={{
								color: `${theme.palette.primary.main}`,
								borderColor: `${theme.palette.primary.main}`,
								'&:hover': {
									borderColor: `${theme.palette.primary.main}`,
									backgroundColor: alpha(theme.palette.primary.main || '#40dadc', 0.1)
								}
							}}
							startIcon={
								<Icon
									sx={{color: `${theme.palette.primary.main}`}}
									type="ListIcon"
									color="primary"
									fontSize="large"
								/>
							}>
							Elenco
						</Button>
					</Link>
					<Link
						to={contentTypeLink + "/create"}
						style={{
							textDecoration: "none",
							color: `${theme.palette.primary.main}`
						}}>
						<Button
							sx={{
								color: `${theme.palette.primary.main}`,
								borderColor: `${theme.palette.primary.main}`,
								'&:hover': {
									borderColor: `${theme.palette.primary.main}`,
									backgroundColor: alpha(theme.palette.primary.main || '#40dadc', 0.1)
								}
							}}
							variant="outlined"
							size="small"
							startIcon={
								<Icon
									sx={{color: `${theme.palette.primary.main}`}}
									type="AddIcon"
									fontSize="large"
								/>
							}>
							Aggiungi
						</Button>
					</Link>
				</CardActions>
			</Card>
		</Grid>
	);
};

// const StyledTableHeadCell = styled(TableCell)(({theme}) => ({
// 	[`&.${tableCellClasses.head}`]: {
// 		backgroundColor: theme.palette.primary.main,
// 		color: theme.palette.common.white,
// 	},
// 	[`&.${tableCellClasses.body}`]: {
// 		fontSize: 14,
// 	},
// }));

// function CardRequestList({theme}) {
// 	const rowsRequest = [
// 		{name: 'user1@name.it', event: 'Nome evento 1', date: '10/12/2023'},
// 		{name: 'user2@name.it', event: 'Nome evento 2', date: '10/12/2023'},
// 		{name: 'user3@name.it', event: 'Nome evento 3', date: '10/12/2023'},
// 		{name: 'user4@name.it', event: 'Nome evento 4', date: '10/12/2023'}
// 	];
	
// 	return (
// 		<Grid item xs={12} sm={8} md={8}>
// 			<Card sx={{height: '100%'}}>
// 				<CardContent>
// 					<Stack
// 						alignItems="center"
// 						direction="row"
// 						justifyContent="space-between"
// 						spacing={3}
// 						paddingBottom={'16px'}
// 					>
// 						<Typography variant="h4" color={`${theme.palette.primary.main}`}>
// 							Le ultime richieste
// 						</Typography>
// 						<Icon
// 							fontSize="large"
// 							type="EventIcon"
// 							sx={{color:`${theme.palette.primary.main}`}}
// 						/>
// 					</Stack>
// 					<TableContainer component={Paper}>
// 						<Table sx={{minWidth: 650}} aria-label="simple table">
// 							<TableHead sx={{background:`${theme.palette.primary.main}`}}>
// 								<TableRow>
// 									<StyledTableHeadCell>Utente</StyledTableHeadCell>
// 									<StyledTableHeadCell>Evento</StyledTableHeadCell>
// 									<StyledTableHeadCell align="right">Data</StyledTableHeadCell>
// 								</TableRow>
// 							</TableHead>
// 							<TableBody>
// 								{rowsRequest.map((row) => (
// 									<TableRow
// 										key={row.name}
// 										sx={{transition:'none', cursor:'pointer', '&:last-child td, &:last-child th': {border: 0}, '&:hover':{backgroundColor:alpha(theme.palette.primary.main || '#40dadc', 0.1), transition:'all .4s linear' }}}
// 									>
// 										<TableCell>{row.name}</TableCell>
// 										<TableCell>{row.event}</TableCell>
// 										<TableCell align="right">{row.date}</TableCell>
// 									</TableRow>
// 								))}
// 							</TableBody>
// 						</Table>
// 					</TableContainer>
// 				</CardContent>
// 				<CardActions sx={{
// 					padding: '16px',
// 					justifyContent: 'space-between'
// 				}}>
// 					<Link
// 						to={'/requests'}
// 						style={{textDecoration: "none", color: `${theme.palette.primary.main}`}}
// 					>
// 						<Button
// 							variant="outlined"
// 							size="small"
// 							sx={{
// 								color: `${theme.palette.primary.main}`,
// 								borderColor: `${theme.palette.primary.main}`,
// 								'&:hover': {borderColor: `${theme.palette.primary.main}`, backgroundColor:alpha(theme.palette.primary.main || '#40dadc', 0.1)}
// 							}}
// 							startIcon={
// 								<Icon
// 									type="ListIcon"
// 									color="primary"
// 									fontSize="large"
// 									sx={{color: `${theme.palette.primary.main}`}}
// 								/>
// 							}>
// 							Vai a tutte le richieste
// 						</Button>
// 					</Link>
// 				</CardActions>
// 			</Card>
// 		</Grid>
// 	);
// };

// function CardAssign({theme}) {
// 	return (
// 		<Grid item xs={12} sm={4} md={4}>
// 			<Card sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color:`${theme.palette.primary.main}`}}>
// 				<CardContent>
// 					<Stack
// 						alignItems="flex-start"
// 						direction="row"
// 						justifyContent="space-between"
// 						spacing={3}
// 					>
// 						<Typography variant="h4">
// 							Associa i contenuti agli utenti
// 						</Typography>
// 						<Icon
// 							fontSize="large"
// 							type="AddIcon"
// 							sx={{
// 								color: `${theme.palette.primary.main}`,
// 								cursor: 'pointer',
// 								borderRadius:'100%',
// 								'&:hover':{
// 									boxShadow: ` inset ${alpha(theme.palette.primary.main || '#40dadc',0.2)} 0px 0px 15px`,
// 									transition:'all .3s linear'
							
// 								}
// 							}}
					
// 						/>
// 					</Stack>
// 				</CardContent>
// 				<CardActions sx={{
// 					padding: '16px',
// 					justifyContent: 'space-between'
// 				}}>
// 					<Link
// 						to={'/assign'}
// 						style={{textDecoration: "none"}}
// 					>
// 						<Button
// 							variant="outlined"
// 							size="small"
// 							sx={{
// 								color: `${theme.palette.primary.main}`,
// 								borderColor: `${theme.palette.primary.main}`,
// 								'&:hover': {borderColor: `${theme.palette.primary.main}`,backgroundColor:alpha(theme.palette.primary.main || '#40dadc', 0.1)}
// 							}}
// 							startIcon={
// 								<Icon
// 									sx={{
// 										color: `${theme.palette.primary.main}`,
// 									}}
// 									type="ListIcon"
// 									color="primary"
// 									fontSize="large"
// 								/>
// 							}>
// 							Vai alla pagina di associazione
// 						</Button>
// 					</Link>
// 				</CardActions>
// 			</Card>
// 		</Grid>
// 	);
// };

export default DasboardHome;