import { ENCRIPT_KEY } from 'api/config';
import CryptoJS from 'crypto-js';
import useEnvironment from "api/useEnvironment";

function useCrypt() {
  const { environment } = useEnvironment();

  function aesEncrypt(body) {
    const message = JSON.stringify(body);
    const data = `${[...Array(16)]
      .map(i => (~~(Math.random() * 36)).toString(36))
      .join('')}${message}`;

    const iv = [...Array(16)]
      .map(i => (~~(Math.random() * 36)).toString(36))
      .join('');

    const cipher = CryptoJS.AES.encrypt(
      data,
      CryptoJS.enc.Utf8.parse(ENCRIPT_KEY[ environment ]),
      {
        iv: CryptoJS.enc.Utf8.parse(iv),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      }
    );
    return cipher.toString();
  };

  return {
    aesEncrypt
  }
}

export default useCrypt;