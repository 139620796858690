import { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
// import _debounce from "lodash.debounce";
import {
  Button,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles"
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import useFetcher from 'api/fetcher';
import useRoutes from 'api/useRoutes';
import NoResults from "components/NoResults";
import Spinner from "components/Spinner";
import SearchBox from "components/SearchBox";
import { FETCHER_MODE } from "utils/FetcherModes";
import useStateDebounce from "utils/useStateDebounce";
import { useContext } from "routes/Assign"

function AssignStep1() {
  const authorization = localStorage.getItem("Authorization");
  const userId = localStorage.getItem("UserId");

  const fetcher = useFetcher();
  // const debouncedFetcher = _debounce(fetcher, 200)
  const theme = useTheme();
  const { getCatalogAssignEndpoint } = useRoutes();
  const [, setActiveStep] = useContext("activeStep")
  const [, setMaximumSessionSeats] = useContext("maximumSessionSeats")
  const [pageSize] = useContext("pageSize")
  const [, setSelectedCalendar] = useContext("selectedCalendar")
  const [selectedPackage, setSelectedPackage] = useContext("selectedPackage")
  const [, setSelectedUsers] = useContext("selectedUsers")
  const [contentFilter, setContentFilter] = useState(null)
  const debouncedContentFilter = useStateDebounce(contentFilter, 200)

  const {
    data: contents,
    hasNextPage,
    isLoading: loadingContents,
    fetchNextPage
  } = useInfiniteQuery({
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    keepPreviousData: true,
    queryKey: ["catalogAssign", pageSize, debouncedContentFilter],
    queryFn: ({ pageParam=null }) => {
      const catalogEndpoint = getCatalogAssignEndpoint({
        page: pageParam,
        size: pageSize,
        title: debouncedContentFilter
      });
      // return debouncedFetcher(FETCHER_MODE.MIDDLEWARE)(
      return fetcher(FETCHER_MODE.MIDDLEWARE)(
        catalogEndpoint,
        {
          authorization,
          method: "GET",
          userId,
        }
      );
    }
  });

  return loadingContents ? (
    <Spinner />
  ) : (
    <>
      <Stack
        boxSizing="border-box"
        divider={<Divider />}
        width="100%"
      >
        <SearchBox
          filter={debouncedContentFilter}
          setFilter={(changeEvent) => {
            setContentFilter(changeEvent.target.value)
          }}
        />
        {
          (
            (Array.isArray(contents?.pages) && contents.pages.length > 0) && 
            (Array.isArray(contents.pages[0].catalog) && contents.pages[0].catalog.length > 0)
          ) ? (
            contents?.pages.map((page) => (
              page.catalog?.filter(el => el.itemId !== 478)?.map((catalogItem) => ( // workaround/hotfix: nascondo pacchetto 478 per non rimuoverlo da db
                <Grid
                  key={`package-row--${catalogItem.id}`}
                  alignItems="center"
                  boxSizing="border-box"
                  container
                  direction="row"
                  paddingX={2}
                  paddingY={4}
                  width="100%"
                >
                  <Grid item xs={1.5} textAlign="center">
                    {
                      (catalogItem.packageType === "premium") ? (
                        <Stack alignItems="center">
                          <StarOutlinedIcon color="primary" fontSize="large" />
                          {/* {
                            catalogItem.calendarChoice === "preselected" ? (
                              <Typography
                                color={theme.palette.info.main}
                                variant="caption"
                              >
                                preselected
                              </Typography>
                            ) : null
                          } */}
                        </Stack>
                      ) : (
                        <Stack alignItems="center">
                          <StarBorderOutlinedIcon color="primary" fontSize="medium" />
                          {/* <Typography
                            color={theme.palette.primary.main}
                            fontWeight={700}
                            fontSize={"0.9rem"}
                            letterSpacing={"0.2ch"}
                            lineHeight={"0.9rem"}
                            >
                            base
                          </Typography> */}
                        </Stack>
                      )
                    }
                  </Grid>
                  <Grid item xs={0.5}/>
                  <Grid item xs={7}>
                    <Typography
                      noWrap
                      variant="h6"
                    >
                      {catalogItem.title}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={6} overflow="hidden">
                    <Typography
                      noWrap
                      textAlign={!catalogItem.description ? "center" : undefined}
                    >
                      {catalogItem.description ?? "---"}
                    </Typography>
                  </Grid> */}
                  <Grid item xs={1}>
                    <Typography
                        color={theme.palette.info.main}
                      >
                        {catalogItem.calendarChoice === "preselected" ? catalogItem.calendarChoice : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign="end">
                    <Button
                      onClick={() => {
                        const shouldAssign = Boolean(catalogItem.packageType === "premium")
                        const nextStep = shouldAssign ? 1 : 2;

                        setActiveStep(nextStep);
                        setMaximumSessionSeats(-1);
                        setSelectedPackage({
                          itemId: catalogItem.itemId,
                          shouldAssign,
                          zuoraProductId: catalogItem.zuoraProductId,
                        });
                        if (selectedPackage.itemId !== catalogItem.itemId) {
                          setSelectedCalendar(null);
                          setSelectedUsers({});
                        };
                      }}
                      sx={ {
                        borderRadius: 2,
                        borderWidth: 2,
                        fontWeight: 700,
                        letterSpacing: 2
                      } }
                      variant="outlined"
                    >
                      assegna
                    </Button>
                  </Grid>
                </Grid>
              ))
            ))
          ) : (
            <NoResults paddingY="1rem"/>
          )
        }
      </Stack>
      <Stack
        alignItems="center"
        boxSizing="border-box"
        width="100%"
      >
        <Divider
          sx={{ marginBottom: 2}}
          width="40%"
        />
        <Button
          disabled={!hasNextPage}
          onClick={() => { hasNextPage && fetchNextPage() }}
        >
          carica altro
        </Button>
      </Stack>
    </>
  )
}

export default AssignStep1;
