import {
  createContext as createReactContext,
  useCallback,
  useContext as useReactContext,
  useRef,
  useSyncExternalStore
} from "react";

export default function createContext(initialState) {
  function useContextData(initialState) {
    const store = useRef(initialState);
  
    const get = useCallback(() => store.current, []);
  
    const subscribers = useRef(new Set());
  
    const set = useCallback((value) => {
      store.current = { ...store.current, ...value };
      subscribers.current.forEach((callback) => callback());
    }, []);
  
    const subscribe = useCallback((callback) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);
  
    return { get, set, subscribe };
  }

  const StoreContext = createReactContext(null);

  function ContextProvider({ children }) {
    const contextData = useContextData(initialState);

    if (!contextData) {
      throw new Error(`contextData not found ${!!contextData}`)
    }

    return (
      <StoreContext.Provider value={contextData}>
        {children}
      </StoreContext.Provider>
    )
  }

  function useContextGetter(selector) {
    const store = useReactContext(StoreContext);

    if (!store) {
      throw new Error("Context not found")
    }

    const state = useSyncExternalStore(
      store.subscribe,
      () => selector(store.get()),
      () => selector(initialState),
    );

    return state;
  }

  function useContextSetter(slice) {
    const store = useReactContext(StoreContext);

    if (!store) {
      throw new Error("Context not found")
    }

    const setter = useCallback((val) => {
      if (!Boolean(slice)) {
        return store.set
      } else {
        return store.set({ [slice]: val })
      }
    }, [slice, store])

    return setter
  }

  function useContext(slice) {
    return [useContextGetter(context => context[slice]), useContextSetter(slice)]
  }

  /* NOTE
   * return useContextSetter to operate on more than on slice at a time
   */
  // return { ContextProvider, useContextGetter, useContextSetter, useContext };
  return { ContextProvider, useContext };
}