import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
	alpha,
	Button,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import {styled} from '@mui/material/styles';
import {format} from "date-fns";
import useFetcher from "api/fetcher";
import useTheme from "api/siteTheme";
import useRoutes from "api/useRoutes";
import Icon from "components/Icon";
import NoResults from "components/NoResults";
import Spinner from "components/Spinner";

const StyledLink = styled(Link)(({theme}) => ({
	color: theme.palette.primary.main,
	textDecoration: "none",
}));

const StyledTableHeadCell = styled(TableCell)(({theme}) => ({
	[`&.${tableCellClasses.head}`]: {
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));


function Contents({contentType, contentTypeTitle}) {
	
	const theme = useTheme();
	const [currentPage, setCurrentPage] = useState(0);
	
	const pageSize = 10;
	// TODO: move react-query logic to custom api hook
	const fetcher = useFetcher();
	const {getContentsEndpoint, getProductsEndpoint} = useRoutes();
	
	const contentsEndpoint = contentType !== 'product_manager' ? getContentsEndpoint(contentType, currentPage, pageSize) : getProductsEndpoint(contentType, currentPage, pageSize);
	
	const {data: contents, isLoading: loadingContents, refetch} = useQuery({
		queryKey: ['contents', contentType, currentPage],
		queryFn: () => fetcher(contentsEndpoint, {method: "GET"})
	});
	useEffect(() => {
		setCurrentPage(0)
		refetch()
	}, [contentType])
	useEffect(() => {
		refetch()
	}, [currentPage]);
	
	
	const currentContents = contents?.data;
	const pages = contents?.pager.total_pages;
	
	return (
		<Grid container sx={{
			flexDirection: "column",
			flexWrap: "nowrap",
			height: "85vh",
			padding: 4,
			width: '100%'
		}}>
			
			<Grid item container
			      alignItems="center"
			      justifyContent="space-between"
			      marginBottom={5}
			
			>
				<Typography
					sx={{
						color: `${theme.palette.primary.main}`,
						textTransform: "capitalize",
					}}
					variant="h3"
				>
					{contentTypeTitle}
				</Typography>
				<Link to={"create"}
				      style={{
					      color: `${theme.palette.primary.main}`,
					      textDecoration: "none",
					      marginRight: 16,
				      }}
				>
					<Button variant="outlined"
					        size="large"
					        sx={{
						        color: `${theme.palette.primary.main}`,
						        borderColor: `${theme.palette.primary.main}`,
						        '&:hover': {
											borderColor: `${theme.palette.primary.main}`,
											backgroundColor: alpha(theme.palette.primary.main || '#40dadc', 0.1)
										}
					        }}
					        startIcon={
						        <Icon
							        type="AddIcon"
							        color={theme.palette.primary.main}
							        fontSize="large"
						        />
					        }
					>
						Aggiungi
					</Button>
				</Link>
			</Grid>
			<Grid
				item
				sx={{minHeight: "100%", overflowX: 'auto', height: '100%'}}
			>
				{loadingContents ? (
					<Spinner/>
				) : (
					Array.isArray(currentContents) && currentContents.length > 0 ? (
						<Table>
							<TableHead sx={{background: `${theme.palette.primary.main}`}}>
								<TableRow sx={{'& td, & th': {border: 0,}}}>
									<StyledTableHeadCell sx={{width: '30px'}}>
										ID
									</StyledTableHeadCell>
									{contentType !== 'teacher' && (
										<StyledTableHeadCell>
											Titolo
										</StyledTableHeadCell>
									)}
									{ColumnCustomForContent(contentType).map((cc, i) => (
										<StyledTableHeadCell key={`${cc.label}-${i}`}>
											{cc.heading}
										</StyledTableHeadCell>
									))}
									{contentType === 'teacher' ? (
										<TableCell/>
									) : null}
									{contentType === 'teacher' ? (
										<TableCell/>
									) : null}
									{contentType !== 'teacher' && (
										<StyledTableHeadCell sx={{width: '80px'}}>
											Pubblicazione
										</StyledTableHeadCell>
									)}
									<StyledTableHeadCell sx={{width: '100px'}}>
										Ultima modifica
									</StyledTableHeadCell>
									<StyledTableHeadCell sx={{textAlign: 'center'}}>
										Azione
									</StyledTableHeadCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{textTransform: "capitalize"}}>
								{currentContents.map((content) => (
									<TableRow
										key={`content--${content.id || content.title}`}
										sx={{
											boxShadow: 1,
											"& .MuiTableCell-body": {paddingY: 3},
											'& td, & th': {border: 0},
										}}
									>
										<TableCell>
											<Typography>
												{content.id || content.product_id || "No ID"}
											</Typography>
										</TableCell>
										{contentType !== 'teacher' && (
											<TableCell>
												<Typography sx={{fontWeight: 400}}>
													{content.title || "No title"}
												</Typography>
											</TableCell>
										)}
										{ColumnCustomForContent(contentType, content).map((cc, i) => (
											<TableCell sx={{textTransform: 'initial'}} key={`${cc.label}-${cc.value}-${i}`}>
												<Typography>{!cc.value && cc.label ? '-' : cc.value && cc.label ? cc.value : ''}</Typography>
											</TableCell>
										))}
										
										{contentType === 'teacher' && (
											<TableCell/>
										)}
										{contentType === 'teacher' && (
											<TableCell/>
										)}
										{contentType !== 'teacher' && (
											<TableCell sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
												
												<Icon
													type={content.published ? 'CheckCircleIcon' : 'UnpublishedIcon'}
													color={content.published ? 'primary' : 'disabled'}
													fontSize="medium"
												/>
											</TableCell>
										)}
										<TableCell>
											<Typography sx={{fontWeight: 400}}>
												{content.updatedDate || content.updated_date ? format(new Date(content.updatedDate || content.updated_date), 'MM/dd/yyyy') : '-'}
											</Typography>
										</TableCell>
										<TableCell sx={{
											textAlign: "center",
											width: "10%"
										}}>
											<StyledLink to={content.id || content.product_id}>
												{/* <Typography>
                            Edit
                          </Typography> */}
												<Button
													size="small"
													variant="outlined"
													sx={{
														color: `${theme.palette.primary.main}`,
														borderColor: `${theme.palette.primary.main}`,
														'&:hover': {
															borderColor: `${theme.palette.primary.main}`,
															backgroundColor:alpha(theme.palette.primary.main || '#40dadc', 0.1)
														}
													}}
													startIcon={
														<Icon
															type="EditOutlinedIcon"
															fontSize="large"
															sx={{ color: `${theme.palette.primary.main}` }}
														/>
													}
												>
													Modifica
												</Button>
											</StyledLink>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
							<TableFooter>
								<TableRow sx={{
									'& td, & th': {
										border: 0,
									},
								}}>
									<TableCell>
										<Typography>Pagina <strong>{currentPage + 1}</strong></Typography>
									</TableCell>
									<TableCell/>
									{ColumnCustomForContent(contentType).map((cc, i) => (
										<TableCell key={`${cc.label}-${i}-footer`}/>
									))}
									<TableCell/>
									<TableCell/>
									<TableCell sx={{
										display: "flex",
										justifyContent: "center"
									}}>
										<IconButton
											sx={{
												'&.Mui-disabled': {
													'.MuiSvgIcon-root': {
														cursor: 'not-allowed',
														pointerEvents: 'auto'
													}
												}
											}}
											disabled={currentPage + 1 <= 1}
											onClick={() => setCurrentPage(prev =>
												
												Math.min(prev - 1)
											)}
										>
											<Icon type="KeyboardDoubleArrowLeftIcon"/>
										</IconButton>
										<IconButton
											sx={{
												'&.Mui-disabled': {
													'.MuiSvgIcon-root': {
														cursor: 'not-allowed',
														pointerEvents: 'auto'
													}
												}
											}}
											disabled={!currentContents || currentPage + 1 >= pages}
											onClick={(e) => {
												setCurrentPage(prev =>
													prev + 1
												)
											}
											}
										>
											<Icon type="KeyboardDoubleArrowRightIcon"/>
										</IconButton>
									</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
					) : (
						<NoResults/>
					)
				)}
			</Grid>
		</Grid>
	);
}

const configCT = {
	collection: ['content'],
	selection: ['content'],
	'learning_object': ['mode', 'duration'],
	news: [],
	'live_event': ['mode', 'duration'],
	survey: [''],
	calendar: [''],
	session: [''],
	package: [''],
	teacher: ['name', 'surname', 'email'],
	questions: [''],
	training: ['type', 'duration'],
	role: [''],
	level: [''],
	'product_manager': ['']
};

function ColumnCustomForContent(ct, content = null) {
	
	const columns = configCT[ct].map((config) => {
		const col = {
			label: config,
			heading: null,
			value: null
		}
		
		if (content) {
			col.value = content[config];
		}
		
		switch (config) {
			case 'content':
				if (content && content.content) {
					const count = content.content.split(",");
					col.value = count.length;
				}
				col.heading = ct === 'collection' ? 'N. Learning Object' : 'N. Training ';
				break;
			case 'type':
				col.heading = 'Tipologia';
				break;
			case 'duration':
				col.heading = 'Durata (sec)';
				break;
			case 'email':
				col.heading = 'Email';
				break;
			case 'surname':
				col.heading = 'Cognome';
				break;
			case 'name':
				col.heading = 'Nome';
				break;
			case 'mode':
				col.heading = 'Tipologia';
				break;
			default:
				break;
		}
		;
		return col;
	});
	return columns;
};

export default Contents;
