/* eslint-disable default-case */
import React, {useEffect} from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Grid,
  Button
} from "@mui/material";
import {styled} from '@mui/material/styles';
import useTheme from "api/siteTheme";
import useFetcher from 'api/fetcher';
import useRoutes from 'api/useRoutes';
import { FETCHER_MODE } from 'utils/FetcherModes';
import { useQuery } from "@tanstack/react-query";
import Icon from "components/Icon";
import Spinner from "../Spinner";
import NoResults from "../NoResults";

	const StyledTableHeadCell = styled(TableCell)(({theme}) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,

			'&.reload': {
				cursor: "pointer"
			}
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		}
	}));

function ReportTable({type}) {
const theme = useTheme();

    const userId = localStorage.getItem("UserId");
    const authorization = localStorage.getItem("Authorization");
	const fetcher = useFetcher();
	const { getReportsEndpoint } = useRoutes();
  const backofficeId = localStorage.getItem('BackofficeId');
  const [reportList, setReportList] = React.useState([]);
  const [nextPageToken, setNextPageToken] = React.useState([]);
	const reportsEndPoint = getReportsEndpoint(type, userId, backofficeId);
  const reportsEndPointPaginated = getReportsEndpoint(type, userId, backofficeId, 5, nextPageToken);

	const { data: reports, isFetching: loadingContents, refetch } = useQuery({
	  queryKey: ["reports", type],
	  queryFn: () => fetcher(FETCHER_MODE.MIDDLEWARE)(
    type === reviewType || type === efReportType ? reportsEndPointPaginated : reportsEndPoint,
      {
        authorization,
        method: "GET"
      }
	  ),
	});

  useEffect(() => {
    setNextPageToken('');
    setReportList([]);
    refetch();
  }, [type])

  const showMore = () => {
    refetch();
    const reportListArray = [...reportList, ...reports.reportList];
    setReportList(reportListArray);
  }

	useEffect(() => {
    if(reports){
      const reportListArray = reports.reportList;
      const nextPage = reports?.pageInfo?.nextPageToken;

      setNextPageToken(nextPage);
      setReportList(reportListArray);
    }
	}, [reports, type])

  const getTypeTranslation = () => {
    switch(type) {
      case 'REVIEWS':
        return 'Recensioni';

      case 'ASSET_FRUITIONS':
        return 'Fruizioni per contenuto';

      case 'TOTAL_FRUITIONS':
        return 'Fruizioni totali';

      case 'LO_FRUITIONS':
        return 'Fruizioni singoli video';

      case 'EF_REPORT':
        return 'Report corsi inglese';
    }
  }

  const reviewType = 'REVIEWS';
  const efReportType = 'EF_REPORT';

  return (
    <>
      {!!loadingContents && type !== reviewType && type !== efReportType ? 
      <Spinner/> :
      !!reportList?.length ? <Table style={{marginBottom: '20px'}}>
        <TableHead sx={{background: `${theme.palette.primary.main}`}}>
            <TableRow sx={{'& td, & th': {border: 0,}}}>
                {type !== efReportType ? <StyledTableHeadCell>
                  Tipologia
                </StyledTableHeadCell> :
                <StyledTableHeadCell>
                  Nome file
               </StyledTableHeadCell>}
                {type !== reviewType && type !== efReportType ? <StyledTableHeadCell>
                  Range temporale
                </StyledTableHeadCell> :
                <StyledTableHeadCell>
                  Data elaborazione
                </StyledTableHeadCell>
                }
                <StyledTableHeadCell>
                    Download
                </StyledTableHeadCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {Array.isArray(reportList) && reportList.map((report) => (
                <TableRow
                    key={`report--${report.fileName}`}
                    sx={{
                        boxShadow: 1,
                        "& .MuiTableCell-body": {paddingY: 3},
                        '& td, & th': {border: 0},
                    }}>
                    <TableCell>
                    {type !== efReportType ? <Typography>
                        {getTypeTranslation(report.reportType)}
                    </Typography> :
                    <Typography>
                      {report.fileName}
                    </Typography>}
                    </TableCell>
                    <TableCell>
                      {type !== reviewType && type !== efReportType ? <Typography>
                        {report.rangeTime > 0 ?
                            <>Ultimi {report.rangeTime} giorni</>
                            : '-'}
                      </Typography> : 
                      <Typography>
                          {report.elaborationDate}
                      </Typography>}
                    </TableCell>
                    <TableCell>
                            <Icon
                            onClick={() => {
                                const xhr = new XMLHttpRequest();
                                xhr.open('GET', report.fileUri);
                                xhr.setRequestHeader('Authorization', localStorage.getItem('Authorization'), 'Content-Type', 'application/json');
                                xhr.responseType = 'blob';
                                xhr.onload = function() {
                                  if (xhr) {
                                        const url = window.URL.createObjectURL(xhr.response) ;
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', report.fileName);
                                        document.body.appendChild(link);
                                        link.click();
                                  }
                                }
                              xhr.send();
                            }}
                            sx={{color: `${theme.palette.primary.main}`}} type={'DownloadIcon'} />
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
      </Table> :
        <Grid
          item xs={12}
          align="center"
          alignItems="center"
          justifyContent="center"
          variant="h3"
          gutterBottom
          color={theme.palette.primary.main}>
          {!loadingContents && <NoResults/>}
        </Grid>
      }
      {!!loadingContents && (type === reviewType || type === efReportType) && <Spinner/>}
      {reports?.pageInfo?.hasNextPage && (type === reviewType || type === efReportType) && !loadingContents && <Button size={'small'} 
        sx={{margin: 'auto', display: 'flex', marginTop: '20px'}} onClick={() => showMore()}>Carica altro</Button>}
    </>
  );
};

export default ReportTable;