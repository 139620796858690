import React, {useState, useEffect} from "react";
import {
	Box,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button
} from "@mui/material";
import {useQuery} from "@tanstack/react-query";
import useFetcher from "api/fetcher";
import useRoutes from "api/useRoutes";

function InputSelectStatic({
	disabled,
	endAdornment,
	label,
	optionsPath,
	setValue,
	value,
	...props
}) {
	const fetcher = useFetcher();
	const {getOptionsEndpoint} = useRoutes();
	const [pager, setPager] = useState(0);
	const [options, setOptions] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [optionsUrl, setOptionsUrl] = useState("");
	const [showMore, setShowMore] = useState();

	const {data: optionsFinded, isLoading: optionsAreLoading, refetch} = useQuery({
		keepPreviousData: true,
		queryKey: ["options", optionsPath?.replace('/', ""), optionsUrl],
		queryFn: () => fetcher(optionsUrl, {method: "GET"}),
	});

	useEffect(() => {
		setOptionsUrl(getOptionsEndpoint(optionsPath, undefined, pager, 10));
		refetch();

		const optionsUpdate = pager === 0 ? optionsFinded?.data : [...options, ...optionsFinded?.data];

		let optionsUnique = optionsUpdate?.reduce((unique, o) => {
			if(!unique.some(obj => obj.id === o.id)) {
			unique.push(o);
			}
			return unique;
		},[]);

		setOptions(optionsUnique);
		
	}, [pager, optionsFinded]);

	useEffect(() => {
		if(!isOpen) {
			setPager(0);
		}
	}, [isOpen]);

	const totalPages = optionsFinded?.pager.total_pages

	return (
		<FormControl {...props}>
			<InputLabel id={`static-select-${label}`}>
				{label}
			</InputLabel>
			{optionsAreLoading ? (
				<Box sx={{
					alignItems: "center",
					display: "flex",
					justifyContent: "center",
				}}>
					<CircularProgress />
				</Box>
			) : (
				<Select
					defaultValue={""}
					disabled={disabled}
					id={`static-select-${label}`}
					endAdornment={endAdornment}
					onChange={setValue}
					onOpen={() => {setIsOpen(true)}}
					open={isOpen}
					onClose={() => value !== 'button' ? setIsOpen(false) : null }
					sx={{
						".MuiSelect-select": {
							"&:focus": {
								backgroundColor: "initial"
							}
						}
					}}
					value={value}
				>
					{
						options?.map((option, idx) => (
							<MenuItem
								key={`option--${option.title}-${idx}`}
								value={option.title}
								onClick={() => setIsOpen(false)}
							>
								{option.title}
							</MenuItem>
						))
					}

					{<MenuItem
						key={`option--button`}
						value={'button'}
						onClick={() => {setPager(pager + 1); setIsOpen(true); setValue('button')}}
					>
						<Button size={'small'} 
							sx={{margin: 'auto', display: 'flex'}}>show more</Button>
					</MenuItem>}
				</Select>
			)}
		</FormControl>
	);
};

export default InputSelectStatic;