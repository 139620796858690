import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import useFetcher from "api/fetcher";
import useUpload from "api/uploader";
import InputCheckbox from "components/InputCheckbox";
import InputHTML from "components/InputHTML";
import InputSelect, { inputSelectValidator } from "components/InputSelect";
import InputText, { inputTextValidator } from "components/InputText";

const validationSchema = yup.object({
  ...inputTextValidator({ controlLabel: "text1", controlIsRequired: true }),
  ...inputSelectValidator({ controlLabel: "select1", controlIsRequired: 1 }),
  cover1: yup
    .mixed()
    // .required("provide a cover, plz")
    .test({
      message: "give me an image",
      test: (file, context) => {
        if(!file) return true;
        const isValid = [
          'image/jpeg',
          'image/jpg',
          'image/png'
        ].includes(file.type);
        // create an error in the formik context
        if (!isValid) {
          const type = file.type.match(/(?!\/)[0-9A-Za-z]+$/)[0]
          return context?.createError({
            message: `can't upload ${type} files`
          })
        }
        return isValid;
      },
    })
    .test({
      message: "I want something smaller",
      test: (file) => {
        if(!file) return true;
        return file.size < 10_000_000 // less than 10MB
      },
    }),
  cover2: yup
    .mixed()
    // .required("provide a cover, plz")
    .test({
      message: "give me an image",
      test: (file, context) => {
        if(!file) return true;
        const isValid = [
          'image/jpeg',
          'image/jpg',
          'image/png'
        ].includes(file.type);
        // create an error in the formik context
        if (!isValid) {
          const type = file.type.match(/(?!\/)[0-9A-Za-z]+$/)[0]
            // get the alphanumeric substring after the last forward slash
          return context?.createError({
            message: `can't upload ${type} files`
          })
        }
        return isValid;
      },
    })
    .test({
      message: "I want something smaller",
      test: (file) => {
        if(!file) return true;
        return file.size < 10_000_000 // less than 10MB
      },
    }),
  checkbox1: yup.boolean()
});

function Examples() {
  const [selectInput, setSelectInput] = useState("");
  // TODO: move react-query logic to custom api hook
  const fetcher = useFetcher();
  const { data: options, isLoading: loadingOptions } = useQuery({
    queryKey: ["examples"],
    queryFn: () =>
      fetcher(`http://localhost:3001/options?title_like=${selectInput}&_page=1&_limit=10`),
  });

  const { upload } = useUpload();
  const formik = useFormik({
    initialValues: {
      text1: "",
      select1: [],
      wysiwyg1: "<p><p>\n",
      cover1: "",
      cover2: "",
      checkbox1: false,
    },
    validationSchema,
    onSubmit: (values) => {
    // Formik auto resets isSubmitting to false when your onSubmit handler returns a Promise

      const apiEndpoint = "http://localhost:3001/images"
      const apiPayload = Object.entries(values)
        .filter(([key, value]) => (
          (key === "cover1" || key === "cover2")
          && Boolean(value)
        ))
        .map(([key, value]) =>
          ({ url: apiEndpoint, file: value, field: key })
        );

      if (!apiPayload.length) {
        handleFormSubmit(values);
      } else {
        const uploadResponse = upload(apiPayload)
        Promise.all(uploadResponse
          // .map(p => p.catch(error => console.error(error)))
        )
        .then(fileValues => {
          fileValues.forEach(({ field, data}) => {
            values[field] = data.id
          });
          handleFormSubmit(values);
        })
        .catch(error => console.error(error));
      }

      formik.setSubmitting(false);
    },
  });

  return (
    <Grid container>
      <Grid item xs={2}></Grid>
      <Grid item container xs={8}>
        <Grid item xs={12}>
          <Typography>
            Componenti da usare nelle form
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <InputText
              controlLabel="text1"
              controlForm={formik}
            />
            <InputSelect
              controlForm={formik}
              controlLabel="select1"
              controlOptions={options}
              optionsAreLoading={loadingOptions}
              setControlFilter={setSelectInput}
            />
            <InputHTML
              controlForm={formik}
              controlLabel="wysiwyg1"
            />
            <FormControl fullWidth>
              <Box sx={{
                alignItems: "center",
                border: 1,
                borderColor: "#d9d9d9",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}>
                <Typography marginX={1}>
                  Content Cover
                </Typography>
                <Input
                  // should not set "value" to anything else than undefined
                  id="cover1"
                  name="cover1"
                  label="cover1"
                  disableUnderline
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "cover1",
                      event.currentTarget.files[0],
                    );
                    formik.setTouched({ "cover1": true }, true);
                  }}
                  error={formik.touched.cover1 && Boolean(formik.errors.cover1)}
                />
              </Box>
              <FormHelperText
                id="cover1"
                error={formik.touched.cover1 && Boolean(formik.errors.cover1)}
              >
                {formik.touched.cover1 && formik.errors.cover1}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth>
              <Box sx={{
                alignItems: "center",
                border: 1,
                borderColor: "#d9d9d9",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}>
                <Typography marginX={1}>
                  Content Cover
                </Typography>
                <Input
                  // should not set "value" to anything else than undefined
                  id="cover2"
                  name="cover2"
                  label="cover2"
                  disableUnderline
                  type="file"
                  accept="image/*"
                  onChange={(event) => {
                    formik.setFieldValue(
                      "cover2",
                      event.currentTarget.files[0],
                    );
                    formik.setTouched({ "cover2": true }, true);
                  }}
                  error={formik.touched.cover2 && Boolean(formik.errors.cover2)}
                />
              </Box>
              <FormHelperText
                id="cover2"
                error={formik.touched.cover2 && Boolean(formik.errors.cover2)}
              >
                {formik.touched.cover2 && formik.errors.cover2}
              </FormHelperText>
            </FormControl>
            <InputCheckbox
              controlLabel="checkbox1"
              controlForm={formik}
            />
            <Button
              fullWidth
              sx={{ marginTop: "10px" }}
              type="submit"
              variant="contained"
            >salva</Button>
          </form>
        </Grid>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  )

  function handleFormSubmit(values) {
    // handle form
    const output = Object.entries(values)
    .reduce((acc, [key, value]) => {
      switch (key) {
        case "select1":
          acc[key] = value.map(obj => obj.id).toString();
          break;
        // case "cover1":
        // case "cover2":
        //   acc[key] = {
        //     fileName: value.name, 
        //     type: value.type,
        //     size: `${value.size} bytes`
        //   }
        //   break;
        default:
          acc[key] = value;
      }
      return acc;
    }, {});
  console.log("form payload", JSON.stringify(output, null, 4));
  }
};

export default Examples;