import Icon from "@/components/Icon";
import { Box, Typography } from "@mui/material";

function NoResults({ text = 'Nessun risultato' , ...props}) {
  return (
    <Box {...props}>
      <Typography
        variant="h5" component="h2"
        sx={{
          padding: '30px',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          border: '1px solid #ccc',
          borderRadius: '10px'
        }}>
             {text}
        <Icon
          type="SentimentDissatisfiedIcon"
          color="primary"
          fontSize="large"
        />
      </Typography>
    </Box>
  )
}

export default NoResults;