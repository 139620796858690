import { Divider } from '@mui/material';
import { CardContainer, Container } from './style';
import { Children, cloneElement } from 'react';

function MonitoringCard({ title, active, children, onClick }) {
    return (
        <CardContainer className={active ? 'active' : ''} onClick={onClick}>
            <div className="title">
                {title}
            </div>
            <Divider sx={{ marginBottom: '19px', marginTop: '12px', color: '#515151', borderBottomWidth: '1px' }}
                width="100%" />
            {Children.map(children, child => {
                return cloneElement(child, { color: active ? child.props.color : '' });
            })}

        </CardContainer>
    );
}

function MonitoringCardInfo({ desc, value, color }) {
    return (
        <Container color={color}>
            <div className='description'>
                {desc}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
                <div>SLA <strong>RESULT</strong></div>
                <div className='value'>
                    {value}
                </div>
            </div>
        </Container>
    );
}

export { MonitoringCardInfo, MonitoringCard };

