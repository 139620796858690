import React from "react";

import {
    Grid,
    Divider
} from "@mui/material";
import { MonitoringCard, MonitoringCardInfo } from '@/components/MonitoringCard';
import LineChart from '@/components/MonitoringCard/LineChart';
import useTheme from "api/siteTheme";
import {ChartsContainer} from "@/routes/Monitoring/style";

const targetMaringSla = {
    0: [{ target: 95, marginMin: 85 }],
    1: [{ target: 4, marginMin: 8 }, { target: 8, marginMin: 16 }],
    2: [{ target: 4, marginMin: 7 }, { target: 8, marginMin: 14 }],
}

function PlatformContent({ dataset, activeCardIndex, setActiveCardIndex, dataLabels, nextYear, prevYear }) {
    const theme = useTheme();
    let cards = dataset.cards;
    let kpis = cards[activeCardIndex].kpis
    return (
        <>
            <Grid
                display={"flex"}
                width={'100%'}
                columnGap={'10px'}
                rowGap={'10px'}
                sx={{
                    [theme.breakpoints.down('lg')]: {
                        flexDirection: 'column'
                    }
                }}
            >
                <MonitoringCard active={activeCardIndex === 0} title={cards[0].title} onClick={() => setActiveCardIndex(0)}>
                    {/*'FRUIBILITÀ DELLA PIATTAFORMA'*/}
                    <MonitoringCardInfo color={cards[0].kpis[0].color} desc={cards[0].kpis[0].title} value={cards[0].kpis[0].average + '%'} />
                </MonitoringCard>

                <MonitoringCard active={activeCardIndex === 1} title={'Presa in carico della segnalazione'} onClick={() => setActiveCardIndex(1)}>
                    <MonitoringCardInfo color={cards[1].kpis[0].color} desc={'PRESA IN CARICO INACESSIBILITÀ'} value={cards[1].kpis[0].average + ' ore'} />
                    <Divider sx={{ marginTop: '13px', marginBottom: '6px', color: '#E0E0E0', borderBottomWidth: '0.5px' }} />
                    <MonitoringCardInfo color={cards[1].kpis[1].color} desc={'PRESA IN CARICO MALFUNZIONAMENTO SPECIFICO'} value={cards[1].kpis[1].average + ' ore'} />
                </MonitoringCard>

                <MonitoringCard active={activeCardIndex === 2} title={'Risoluzione della segnalazione'} onClick={() => setActiveCardIndex(2)}>
                    <MonitoringCardInfo color={cards[2].kpis[0].color} desc={'RISOLUZIONE INACESSIBILITÀ'} value={cards[2].kpis[0].average + ' giorni'} />
                    <Divider sx={{ marginTop: '13px', marginBottom: '6px', color: '#E0E0E0', borderBottomWidth: '0.5px' }} />
                    <MonitoringCardInfo color={cards[2].kpis[1].color} desc={'RISOLUZIONE MALFUNZIONAMENTO SPECIFICO'} value={cards[2].kpis[1].average + ' giorni'} />
                </MonitoringCard>
            </Grid>
            <div style={{ width: '100%' }}>
                {kpis.map(function (kpi, index) {
                    return kpi.dataset && <ChartsContainer key={`chart_platform_${activeCardIndex}_${index}_${kpi.selectedYearIndex}`} className={kpis.length > 1 ? 'halfWidth' : ''}>
                            <LineChart key={kpi.title} dataset={kpi.dataset} kpi={kpi} lineColor={kpi.color} dataLabels={dataLabels} targetMaringSla={targetMaringSla[activeCardIndex][index]} clickYearPrev={()=>prevYear(index)} clickYearNext={()=>nextYear(index)}></LineChart>
                        </ChartsContainer>
                })}
            </div>

        </>
    )
};

export default PlatformContent;