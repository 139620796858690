import React from "react";
import InputCheckbox from "components/InputCheckbox";
import InputFile from "components/InputFile";
import InputHTML from "components/InputHTML";
import InputMask from "components/InputMask";
import InputSelect from "components/InputSelect";
import InputText from "components/InputText";
import DatePickerInput from "components/DatePicker";
import InputMultiField from "components/InputMultiField";
import InputAssociation from "components/InputAssociation";
import InputSimpleSelect from "components/InputSimpleSelect";
export { inputFileValidator } from "components/InputFile";
export { inputSelectValidator } from "components/InputSelect";
export { inputTextValidator } from "components/InputText";
export { inputDatePickerValidator } from "components/DatePicker";
export {inputMultiFieldValidator} from "components/InputMultiField";
export {inputAssociationValidator} from "components/InputAssociation";
export {inputSimpleSelectValidator} from "components/InputSimpleSelect"

function InputField({ contentFields, field, form , theme, contentType, isCreating, id, disabledDate}) {

  switch(field.fieldType) {
    case "boolean":
      return (
        <InputCheckbox
          contentFields={contentFields}
          controlForm={form}
          controlGroup={field.fieldGroup}
          controlLabel={field.fieldLabel}
          controlName={field.fieldName}
          contentType={contentType}
          isCreating={isCreating}
          required={field.required}
        />
      );
    case "file" :
      return (
        <InputFile
          contentFields={contentFields}
          controlForm={form}
          controlGroup={field.fieldGroup}
          controlLabel={field.fieldLabel}
          controlName={field.fieldName}
          required={field.required}
        />
      );
    case "html":
      return (
        <InputHTML
          contentFields={contentFields}
          controlForm={form}
          controlGroup={field.fieldGroup}
          controlLabel={field.fieldLabel}
          controlName={field.fieldName}
          required={field.required}
          contentType={contentType}
          id={id}
        />
      );
    case "mask":
      return (
        <InputMask
          contentFields={contentFields}
          controlForm={form}
          controlGroup={field.fieldGroup}
          controlLabel={field.fieldLabel}
          controlFields={field.subFields}
          controlName={field.fieldName}
          required={field.required}
        />
      );
    case "text":
      return (
        <InputText
          contentFields={contentFields}
          controlForm={form}
          controlGroup={field.fieldGroup}
          controlLabel={field.fieldLabel}
          controlName={field.fieldName}
          isCreating={isCreating}
          required={field.required}
          disabledDate={disabledDate}
        />
      );
    case "select":
      return (
        <InputSelect
          contentFields={contentFields}
          controlForm={form}
          controlGroup={field.fieldGroup}
          controlLabel={field.fieldLabel}
          controlName={field.fieldName}
          multiple={field.maxValues !== 1}
          optionsEndpoint={field.endpoint}
          property={field.property}
          optionsStatic={field.optionsStatic}
          isCreating={isCreating}
          required={field.required}
          contentType={contentType}
        />
      );
	  case "select_file":
		  return (
			  <InputSelect
				  contentFields={contentFields}
				  controlForm={form}
				  controlGroup={field.fieldGroup}
				  controlLabel={field.fieldLabel}
				  controlName={field.fieldName}
				  multiple={field.maxValues !== 1}
				  optionsEndpoint={field.endpoint}
				  property={field.property}
				  optionsStatic={field.optionsStatic}
				  theme={theme}
				  isCreating={isCreating}
				  required={field.required}
			  />
		  );
	  case "select_media":
		  return (
			  <InputSelect
				  contentFields={contentFields}
				  controlForm={form}
				  controlGroup={field.fieldGroup}
				  controlLabel={field.fieldLabel}
				  controlName={field.fieldName}
				  multiple={field.maxValues !== 1}
				  optionsEndpoint={field.endpoint}
				  property={field.property}
				  optionsStatic={field.optionsStatic}
				  isCreating={isCreating}
				  required={field.required}
			  />
		  );
	  case "dateInfo":
		  return (
			  <DatePickerInput
				  contentFields={contentFields}
				  controlForm={form}
				  controlGroup={field.fieldGroup}
				  controlLabel={field.fieldLabel}
				  controlName={field.fieldName}
				  required={field.required}
          disabledDate={disabledDate}
			  />
		  );
	  case "multiField" :
			return (
			<InputMultiField
				controlName={field.fieldName}
				controlGroup={field.fieldGroup}
				controlLabel={field.fieldLabel}
				controlForm={form}
				contentFields={contentFields}
				required={field.required}
			
			/>
	  );
	  case "documents" :
		  return (
			  <InputMultiField
				  controlName={field.fieldName}
				  controlGroup={field.fieldGroup}
				  controlLabel={field.fieldLabel}
				  controlForm={form}
				  contentFields={contentFields}
				  required={field.required}
			  />
		  );
	  case "association" :
		  return (
			  <InputAssociation
				  controlName={field.fieldName}
				  controlGroup={field.fieldGroup}
				  controlLabel={field.fieldLabel}
				  controlForm={form}
				  contentFields={contentFields}
				  optionsEndpointLiveEvent={field.endpointLive_events}
				  optionsEndpointSession={field.endpointSession}
				  required={field.required}
			  />
		  );
	  case "simple_select":
			return (
				<InputSimpleSelect
					contentFields={contentFields}
					controlForm={form}
					controlGroup={field.fieldGroup}
					controlLabel={field.fieldLabel}
					controlName={field.fieldName}
					required={field.required}
				/>
			)
    default:
      return <></>;
  };
};

export function resetGroupFields(fields, form, group, name) {

  if (!isNaN(group)) {
    fields
      .filter((field) =>(!isNaN(field.fieldGroup)))
      .forEach((field) => {
        if (
          field.fieldName !== name
          && field.fieldGroup === group
        ) {
          form.setFieldValue(
            field.fieldName, // field
            form.initialValues[field.fieldName],//value
            true, //shouldValidate
          )
        }
      });
  };
};

export default InputField;
