import {
  Stack,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles"

const StyledSearchBox = styled(Stack)(({ theme }) => ({
  alignItems: "flex-end",
  paddingBottom: theme.spacing(2)
}))

function SearchBox({ filter, setFilter }) {
  return (
    <StyledSearchBox>
      <TextField
        margin="normal"
        onChange={(changeEvent) => { setFilter(changeEvent) }}
        placeholder="cerca"
        value={filter}
        variant="standard"
      />
    </StyledSearchBox>
  )
}

export default SearchBox