import styled from "styled-components";

export const ChartsContainer = styled.div`
    &.halfWidth{
      display: inline-block;
      width: calc(50% - 10px);
      &:first-child{
        margin-right: 20px;
      }
      @media (max-width: 1200px){
        width: 100%;
      }
    }
`;