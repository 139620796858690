import React, { useState, useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { BoxChart } from "@/components/MonitoringCard/LineChart/style";
import Icon from "@/components/Icon";

const LineChart = ({ dataset, targetMaringSla, lineColor, kpi, clickYearPrev, clickYearNext }) => {
    const [data, setData] = useState(null);
    const [unit, setUnit] = useState('');
    const [target, setTarget] = useState(null);

    const months = [
        'GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU',
        'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC',
    ];


    let dataLabels = [];
    if (dataset?.isDynamicLabels){
        dataLabels = dataset.value[kpi.yearsArray[kpi.selectedYearIndex]]?.map(row => row.date)
    }else{
        dataLabels = months;
    }

    useEffect(() => {
        if (dataset) {
            const labels = dataLabels;
            const newData = {
                labels,
                datasets: [
                    {
                        label: 'Dataset',
                        data: dataset.value[kpi.yearsArray[kpi.selectedYearIndex]]?.map(row => row.value) || [],
                        fill: true,
                        borderColor: lineColor,
                        backgroundColor: (context) => {
                            const bgColor = [
                                lineColor + '4D', // opacity 30% (4D)
                                '#ffffff'
                            ];
                            if (!context.chart.chartArea) {
                                return;
                            }
                            const { ctx, chartArea: { top, bottom } } = context.chart;
                            const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
                            gradientBg.addColorStop(0, bgColor[0]);
                            gradientBg.addColorStop(1, bgColor[1]);
                            return gradientBg;
                        },
                        borderWidth: 2,
                        pointBackgroundColor: lineColor,
                        pointRadius: 5,
                    },
                ],
            };
            setData(newData);
            const newUnit = dataset.type === 'h' ? 'ore' : dataset.type === 'gg' ? 'giorni' : dataset.type;
            setUnit(newUnit);
        }

    }, [dataset, kpi]);

    useEffect(() => {
        if (targetMaringSla) {
            const value = targetMaringSla;
            setTarget(value);
        }

    }, [targetMaringSla]);


    const getMaxValue = ()=>{
        if (dataset){
            if (dataset.type === '%'){
                return 100;
            }
            if (dataset.value[kpi.yearsArray[kpi.selectedYearIndex]]){
                return Math.max(...(dataset.value[kpi.yearsArray[kpi.selectedYearIndex]]?.map(row => row.value)), 20)
            }
        }
        return 20;
    }
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        clip: false,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                align: 'start',
                text: dataset ? dataset.title.replace("(secondi)", '').toUpperCase() : '',
                color: lineColor
            },
            horizontalArbitraryLine: {
                lineColor1: '#8FD300',
                lineColor2: '#D32600',
                unit: unit,
                yPositionLine1: target != null ? target.target : 0,
                yPositionLine2: target != null ? target.marginMin : 0,
            }
        },
        scales: {
            y: {
                min: 0, //dataset.type === '%' ? 0 : Math.round(Math.max(...(dataset.value[currentYear]?.map(row => row.value)))),
                max: getMaxValue(),
                beginAtZero: true,
                ticks: {
                    stepSize: dataset?.type === '%' ? 10 : 2,
                    callback: function (value, index, ticks) {
                        return value + dataset?.type;
                    }
                }
            }
        },
        layout: {
            padding: {
                left: 20,
                right: 100,
                top: 20,
                bottom: 20
            }
        }
    };

    const horizontalArbitraryLine = {

        id: 'horizontalArbitraryLine',
        beforeDatasetDraw: (chart, args, options) => {

            const { ctx, chartArea: { top, right, bottom, left, width, height }, scales: { x, y } } = chart;
            ctx.save();
            let targetSla = options.yPositionLine1 + options.unit;
            let marginSla = options.yPositionLine2 + options.unit;

            const MARGIN = 100;
            const yThickness = 2;

            const yPixelValue1 = y.getPixelForValue(options.yPositionLine1);
            const yPixelValue2 = y.getPixelForValue(options.yPositionLine2);

            ctx.fillStyle = options.lineColor1;
            ctx.fillRect(left, yPixelValue1, right + MARGIN, yThickness);
            ctx.textAlign = "right";
            ctx.font = "400 10px sans-serif";
            const yText1 = yPixelValue1;
            ctx.fillText("SLA", right + MARGIN, yText1 + 13);
            ctx.font = "900 10px sans-serif";
            ctx.fillText("TARGET", right + MARGIN, yText1 + 23);
            ctx.font = "900 16px sans-serif";
            ctx.fillText(targetSla, right + MARGIN, yText1 + 43);
            ctx.restore();


            //
            ctx.fillStyle = options.lineColor2;
            ctx.fillRect(left, yPixelValue2, right + MARGIN, yThickness);
            ctx.textAlign = "right";
            ctx.font = "400 10px sans-serif";
            const yText2 = yPixelValue2;
            ctx.fillText("SLA", right + MARGIN, yText2 + 13);
            ctx.font = "900 10px sans-serif";
            ctx.fillText("SOGLIA MINIMA", right + MARGIN, yText2 + 23);
            ctx.font = "900 16px sans-serif";
            ctx.fillText(marginSla, right + MARGIN, yText2 + 43);

            ctx.restore();

        }
    };

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend,
        horizontalArbitraryLine
    );

    ChartJS.defaults.backgroundColor = '#00aab4';
    ChartJS.defaults.borderColor = '#E0E0E0';
    ChartJS.defaults.scales.linear.min = 0;
    ChartJS.defaults.scales.linear.max = 100;

    const hasNextYear = ()=>{
        return kpi.selectedYearIndex < kpi.yearsArray.length-1;
    }
    const hasPrevYear = () => {
        return kpi.selectedYearIndex > 0;
    }

    return (
        <BoxChart>
            {data && <Line options={options} data={data} />}
            <div className={'button-container'}>
                <div className={'change-year'}>
                    <Icon type={'WestIcon'} style={{'width': '15px', 'opacity': hasPrevYear() ? 1 : 0.3}} onClick={() => {
                        hasPrevYear() && clickYearPrev()
                    }}/>
                    <span>{kpi.yearsArray[kpi.selectedYearIndex]}</span>
                    <Icon type={'EastIcon'} style={{'width': '15px', 'opacity': hasNextYear() ? 1 : 0.3}} onClick={() => {
                        hasNextYear() && clickYearNext()
                    }}/>
                </div>
            </div>
        </BoxChart>
    );
}

export default LineChart;