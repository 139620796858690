import styled from "styled-components";

export const Container = styled.div`
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items:center;
    color: ${props => props.color};

    .description{
        width:100%;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .value{
        margin-left:13px;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }
    `;
export const CardContainer = styled.div`
  width:100%;
  border-radius: 12px;
  background:  #FFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
  padding : 20px;
  color: #a4a4a4;
  box-sizing: border-box;

  &:hover{
    cursor: pointer;
    outline: 1px solid #a2a2a2;
  }
  &.active{
    border: 1px solid #a2a2a2;
    outline: 0;
    .title{
      color:#515151;
    }
    .description{

    }
  }
  .title {
    color: #a4a4a4;
    font-size: 16;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;