import { useMemo } from "react";
import { useLocation } from "react-router-dom";

function useContentType() {
  /** NOTE:
   * if and when react router will resume support for regex checks on paths 
   * contentType may then be read as query param by setting the route like so
   *   /:contentType/:id
   * until then we can't restrict the value of contentType if set as query param
   * (https://github.com/remix-run/react-router/discussions/8132)
   */
  const location = useLocation();
  const contentType = useMemo(() => (
    // get the second to last section of the pathname string
    location.pathname.split('/').slice(-2)[0]
  ), [location]);
  return contentType;
};

export default useContentType;