export const POST_FIELDS = {
	contentType: 'Crea Post Bacheca',
	fields:
		[
			{
				fieldName: "objectType",
				fieldLabel: "tipologia oggetto",
				fieldType: "text",
				fieldValue: "no_content",
				required: false,
			},
			{
				fieldName: "itemFonte",
				fieldLabel: "fonte oggetto",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "itemFonteImageUrl",
				fieldLabel: "fonte immagine oggetto",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "sharedMessage",
				fieldLabel: "messaggio per la condivisione del post",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "title",
				fieldLabel: "titolo post",
				fieldType: "text",
				fieldValue: "",
				required: true,
			},
			{
				fieldName: "description",
				fieldLabel: "descrizione post",
				fieldType: "html",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "imageUrl",
				fieldLabel: "immagine",
				fieldType: "file",
				fieldValue: null,
				required: false,
			},
			{
				fieldName: "userId",
				fieldLabel: "user id",
				fieldType: "text",
				fieldValue: "",
				required: false,
			},
			{
				fieldName: "itemId",
				fieldLabel: "itemID",
				fieldType: "text",
				fieldValue: null,
				required: false,
			},
		]
	
}