import React from "react";
import {
	Grid,

	Divider,
} from "@mui/material";
import LineChart from '@/components/MonitoringCard/LineChart';
import { MonitoringCard, MonitoringCardInfo } from '@/components/MonitoringCard';
import {ChartsContainer} from "@/routes/Monitoring/style";
const targetMaringSla = {
	0: [{ target: 7, }],
	1: [{ target: 30, marginMin: 50 }, { target: 20, marginMin: 30 }],
}
function LearningObjectContent({ dataset, activeCardIndex, setActiveCardIndex, isDynamicLabels, nextYear, prevYear}) {
	let cards = dataset.cards;
	let kpis = cards[activeCardIndex].kpis
	return (
		<>
			<Grid
				display={"flex"}
				width={'100%'}
				columnGap={'10px'}
			>
				<MonitoringCard active={activeCardIndex === 0} title={cards[0].title} onClick={() => setActiveCardIndex(0)}>
					<MonitoringCardInfo color={cards[0].kpis[0].color} desc={cards[0].kpis[0].title} value={cards[0].kpis[0].average + ' giorni'} />
				</MonitoringCard>
				<MonitoringCard active={activeCardIndex === 1} title={cards[1].title} onClick={() => setActiveCardIndex(1)}>
					<MonitoringCardInfo color={cards[1].kpis[0].color} desc={cards[1].kpis[0].title} value={cards[1].kpis[0].average+'%'} />
					<Divider sx={{ marginTop: '13px', marginBottom: '6px', color: '#E0E0E0', borderBottomWidth: '0.5px' }} />
					<MonitoringCardInfo color={cards[1].kpis[1].color} desc={cards[1].kpis[1].title} value={cards[1].kpis[1].average+'%'} />
				</MonitoringCard>
			</Grid>
			<div style={{ width: '100%' }}>
				{kpis.map(function (kpi, index) { return (<ChartsContainer key={`chart_lo_${activeCardIndex}_${index}_${kpi.selectedYearIndex}`} className={kpis.length > 1 ? 'halfWidth' : ''}>
					<LineChart key={kpi.title} dataset={kpi.dataset} kpi={kpi} lineColor={kpi.color} isDynamicLabels={isDynamicLabels} clickYearPrev={()=>prevYear(index)} clickYearNext={()=>nextYear(index)} targetMaringSla={targetMaringSla[activeCardIndex][index]} ></LineChart>
				</ChartsContainer>) })}
			</div>
		</>

	)
};

export default LearningObjectContent;