import React, {useCallback, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {
	Autocomplete, Button,
	createFilterOptions,
	Grid,
	TextField,
	Typography
} from "@mui/material";
import {FieldArray} from "formik";
import * as yup from "yup";
import _debounce from "lodash.debounce";
import useFetcher from "api/fetcher";
import useRoutes from "api/useRoutes";
import useTheme from "api/siteTheme";
import Icon from "components/Icon";
import {resetGroupFields} from "components/InputField";

function InputAssociationSelect({
	                                contentFields,
	                                controlForm,
	                                controlGroup,
	                                controlLabel,
	                                controlName,
	                                multiple,
	                                optionsEndpointSession,
	                                optionsEndpointLiveEvent,
	                                property,
	                                required
                                }) {
	const theme = useTheme();
	const [selectInputSession, setSelectInputSession] = useState("");
	const [selectInputLiveEvent, setSelectInputLiveEvent] = useState("");
	const [values, setValues] = useState(controlForm.values[controlName]);
	
	
	const [valuesSession, setValuesSession] = useState(
		values.map(value => {
			return {
				session_id: value.session_id,
				session_name: value.session_name
			}
		}));
	
	const [valuesLiveEvent, setValuesLiveEvent] = useState(
		values.map(value => {
			return {
				live_event_id: value.live_event_id,
				live_event_name: value.live_event_name
			}
		}));
	
	
	const filterOptions = createFilterOptions();
	
	const handleSelectInputSession = useCallback(_debounce(setSelectInputSession, 400), []);
	const handleSelectInputLiveEvent = useCallback(_debounce(setSelectInputLiveEvent, 400), []);
	
	// TODO: move react-query logic to custom api hook
	const fetcher = useFetcher();
	const {getOptionsEndpoint} = useRoutes();
	
	const optionsUrlSession = getOptionsEndpoint(optionsEndpointSession, property, selectInputSession);
	const optionsUrlLiveEvent = getOptionsEndpoint(optionsEndpointLiveEvent, property, selectInputLiveEvent);
	const {data: optionsFindedSession, isLoading: optionsSessionAreLoading} = useQuery({
		queryKey: ["options", optionsEndpointSession.replace('/', ""), optionsUrlSession],
		queryFn: () => fetcher(optionsUrlSession, {method: "GET"}),
	});
	const {data: optionsFindedLiveEvent, isLoading: optionsAreLoading} = useQuery({
		queryKey: ["options", optionsEndpointLiveEvent.replace('/', ""), optionsUrlLiveEvent],
		queryFn: () => fetcher(optionsUrlLiveEvent, {method: "GET"}),
	});
	
	const optionsSession = optionsFindedSession?.data?.map(option => {
		return {
			session_id: option.id,
			session_name: option.title
		}
	});
	const optionsLiveEvent = optionsFindedLiveEvent?.data?.map(option => {
		return {
			live_event_id: option.id,
			live_event_name: option.title
		}
	});
	
	useEffect(() => {
		setValues(controlForm.values[controlName])
	}, [controlForm]);
	useEffect(() => {
		setValuesSession(
			values.map(value => {
				return {
					session_id: value.session_id,
					session_name: value.session_name
				}
			}))
		setValuesLiveEvent(
			values.map(value => {
				return {
					live_event_id: value.live_event_id,
					live_event_name: value.live_event_name
				}
			}))
	}, [values])
	
	const valueSchema = {
		live_event_id: '',
		live_event_name: '',
		session_id: '',
		session_name: ''
	}
	

	
	function handleChange(value, values, name, id) {
		
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);

		
		const newValuesSession = name === 'session' ?
			valuesSession.map((item, index) => {
				if (index === id) {
					return {
						...value
					}
				} else {
					return {
						...item
					}
				}
			}) : valuesSession;
		
		
		const newValuesLiveEvent = name === 'liveEvent' ?
			valuesLiveEvent.map((item, index) => {
				if (index === id) {
					return {
						...value
					}
				} else {
					return {
						...item
					}
				}
			}) : valuesLiveEvent;
		
		const newValues = newValuesLiveEvent.map((item, index) => {
			
			return {
				 ...item,
				...newValuesSession.find((eventValue, ix) => ix === index)
			}
		})
		

		
		
		setValues(newValues)
		
		controlForm.setFieldValue(controlName, newValues, true);
		controlForm.setTouched({controlName: true}, true);
	}
	
	function handleChangeDelete(values) {
		
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		
		const newValues = values.map((valueItem, index) => {
			
			return {
				...valueItem
				
			}
			
		});
		setValues(newValues)
		
		controlForm.setFieldValue(controlName, newValues, true);
		controlForm.setTouched({controlName: true}, true);
	}
	
	return (
		<Grid
			item
			fullwidth
			sx={{
				background: '#8080801a',
				borderRadius: '10px',
				padding: '10px',
			}}
		>
			<div style={{
				padding: '16px 0',
				color: `${theme.palette.primary.main}`
			}}>
				{`${controlLabel}${required ? '*' : ''}`}
			</div>
			
			<FieldArray
				name={`${controlName}`}
				sx={{
					border: `1px solid ${theme.palette.primary.main}`,
					borderRadius: '10px',
					padding: '16px',
					width: '100%'
				}}
				validateOnChange={true}
			>
				{() => (
					<>
						{values?.map((item, index) => (
							<div key={index}>
								<div className="header" style={{
									padding: '16px 0',
									color: `${theme.palette.primary.main}`,
									width: '60%',
									margin: '0 auto',
									display: 'flex',
									justifyContent: 'space-between'
								}}>
									<div></div>
									<Icon type={'TrashIcon'} sx={{
										cursor: 'pointer',
										'&:hover': {color: 'rgb(35, 116, 124)'}
									}} onClick={() => {
										setValues(values.filter((value, ix) => ix !== index))
										handleChangeDelete(values.filter((value, ix) => ix !== index))
									}}/>
								</div>
								
								<Grid width={'60%'} sx={{
									borderRadius: '20px',
									padding: '16px',
									margin: '10px auto',
									boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 15px;',
									border: '1px solid #c4c4c4',
									background: 'white',
									'&:hover': {
										borderColor: `${theme.palette.primary.main}`
									}
								}}>
									
									{
										valuesSession.map((value, ix) => {
											
											if (ix === index) {
									
												return <Autocomplete
													key={`session-${ix}`}
													sx={{paddingBottom: '16px'}}
													id={`session-${ix}`}
													name={`session-${ix}`}
													label={'session'}
													defaultValue={[]}
													isOptionEqualToValue={(option, value) => option.session_id === value.session_id}
													filterOptions={
														multiple
															? (x) => x
															: (optionsSession, state, c) => filterOptions(optionsSession, state)
													}
													getOptionLabel={(option) => `${option.session_name} (${ option.session_id})`}
													loading={optionsAreLoading}
													multiple={false}
													noOptionsText="Nessuna opzione disponibile"
													onInputChange={(event, newSelectValue) => {
														handleSelectInputSession(newSelectValue)
														
													}}
													options={optionsSession || []}
													renderInput={(params) => (
														<TextField
															{...params}
															placeholder="Seleziona alcuni contenuti"
															label={controlLabel}
															error={controlForm.touched[controlName]
																&& Boolean(controlForm.errors[controlName])}
															helperText={controlForm.touched[controlName]
																&& controlForm.errors[controlName]}
															sx={{
																'& .MuiOutlinedInput-root': {
																	'&:hover fieldset': {
																		borderColor: `${theme.palette.primary.main}`,
																	},
																},
																
															}}
														/>
													)}
													renderOption={(props, option) => (
														<li {...props}>
															<Grid container alignItems="center">
																<Grid item>
																	<Typography>
																		{`${option.session_name} (${ option.session_id})`}
																	</Typography>
																</Grid>
															</Grid>
														</li>
													)}
													onChange={(e,val) => handleChange(val, values, 'session', ix)}
												  value={value.session_id ? value : null}
												/>
											} else {
												return null
											}
										})
									}
									{
										valuesLiveEvent.map((value, ix) => {
											
											if (ix === index) {
									
												return <Autocomplete
													key={`live_event-${ix}`}
													id={`live_event-${ix}`}
													name={`live_event-${ix}`}
													label={'Live Event'}
													defaultValue={[]}
													isOptionEqualToValue={(option, value) => option.live_event_id === value.live_event_id}
													filterOptions={
														multiple
															? (x) => x
															: (optionsLiveEvent, state, c) => filterOptions(optionsLiveEvent, state)
													}
													getOptionLabel={(option) => `${option.title || option.name || option.live_event_name} (${option.id || option.live_event_id})`}
													loading={optionsAreLoading}
													multiple={false}
													noOptionsText="Nessuna opzione disponibile"
													onInputChange={(event, newSelectValue) => {
														handleSelectInputLiveEvent(newSelectValue)
														
													}}
													options={optionsLiveEvent || []}
													renderInput={(params) => (
														<TextField
															{...params}
															placeholder="Seleziona alcuni contenuti"
															label={'Live Event'}
															error={controlForm.touched[controlName]
																&& Boolean(controlForm.errors[controlName])}
															helperText={controlForm.touched[controlName]
																&& controlForm.errors[controlName]}
															sx={{
																'& .MuiOutlinedInput-root': {
																	'&:hover fieldset': {
																		borderColor: `${theme.palette.primary.main}`,
																	},
																},
																
															}}
														/>
													)}
													renderOption={(props, option) => (
														<li {...props}>
															<Grid container alignItems="center">
																<Grid item>
																	<Typography>
																		{`${option.live_event_name} (${ option.live_event_id})`}
																	</Typography>
																</Grid>
															</Grid>
														</li>
													)}
													onChange={(e,val) => handleChange(val, values, 'liveEvent', ix)}
													value={value.live_event_id ? value : null}
												/>
											} else {
												return null
											}
										})
									}
								</Grid>
							</div>
						))}
						<Button
							variant="contained"
							sx={{
								color: 'white',
								background: `${theme.palette.primary.main}`,
								margin: '16px auto 0',
								width: 'fit-content',
								display: 'flex'
							}}
							onClick={() => {
								const added = [...values, valueSchema];

								setValues(added);
								
							}}
						>
							Aggiungi sessione
						</Button>
					</>
				)}
			</FieldArray>
		</Grid>
	
	);
	
	
};


export const inputAssociationValidator = ({
	                                          controlLabel,
	                                          controlName,
	                                          controlMaxValues = 1,
	                                          controlMinValues = 0
                                          }) => {
	const validator = {
		[controlName]: yup
			.array("select an option").of(yup.object({
				session_id: yup.string(),
				session_name: yup.string(),
				live_event_name: yup.string(),
				live_event_id: yup.string(),
			}))
		
	}
	if (controlMinValues > 0) {
		validator[controlName] =
			validator[controlName]
				.required(`${controlLabel} è obbligatorio`)
				.min(controlMinValues, `Inserisci almeno ${controlMinValues} values`)
	}
	if (controlMaxValues > 0) {
		validator[controlName] =
			validator[controlName]
				.max(controlMaxValues, `Inserisci al massimo ${controlMaxValues} ${controlMaxValues > 1 ? 'valori' : 'valore'}`)
	}
	return validator;
};

export default InputAssociationSelect;
