import { useQuery } from "@tanstack/react-query";
import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useFetcher from "api/fetcher";
import useRoutes from "api/useRoutes";
import _debounce from "lodash.debounce";
import { FETCHER_MODE } from "utils/FetcherModes";
import NoResults from "components/NoResults";
import Spinner from "components/Spinner";
import { useContext } from "routes/Assign";

function AssignStep2() {
  const fetcher = useFetcher();
  const debouncedFetcher = _debounce(fetcher, 200)
  const { getCalendarsEndpoint } = useRoutes();
  const theme = useTheme()
  const [, setMaximumSessionSeats] = useContext("maximumSessionSeats")
  const [selectedCalendar, setSelectedCalendar] = useContext("selectedCalendar")
  const [selectedPackage] = useContext("selectedPackage")

  const calendarsEndpoint = getCalendarsEndpoint({
    itemId: selectedPackage.zuoraProductId,
  });
  const { data: response, isLoading: loadingSessions } = useQuery({
    keepPreviousData: true,
    queryKey: ["packageCalendars", selectedPackage.zuoraProductId],
    queryFn: () => debouncedFetcher(FETCHER_MODE.MIDDLEWARE)(calendarsEndpoint, { method: "GET" }),
  });

  return loadingSessions ? (
    <Spinner />
  ) : (
    <Stack
      boxSizing="border-box"
      divider={<Divider />}
      width="100%"
    >

     {Array.isArray(response?.calendars) && response.calendars.length > 0 ? (
        response.calendars.map((calendar) => (
          <div className="calendar-item">
            {calendar.sessions.map((session) => (<Grid
              key={`session-row--${session.sessionId}`}
              alignItems="center"
              boxSizing="border-box"
              container
              direction="row"
              paddingX={2}
              paddingY={4}
              width="100%"
            >
              <Grid item xs={1} />
              <Grid item xs={8}>
                <FormControl>
                  <RadioGroup
                    onChange={(changeEvent) => {
                      setMaximumSessionSeats(session.totalSeats)
                      setSelectedCalendar(changeEvent.target.value)
                    }}
                    value={selectedCalendar}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      disabled={(session.totalSeats < 1)}
                      label={session.sessionTitle}
                      value={session.sessionId}
                    />
                      
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                {
                  (session.totalSeats < 1) ? (
                    <Typography color={theme.palette.warning.main}>
                      Posti esauriti
                    </Typography>
                  ) : null
                }
              </Grid>
            </Grid>))}
          </div>
        ))
       ) : (
           <NoResults text={'Nessuna sessione disponibile'}/>
         ) }
    </Stack>
  )
}

export default AssignStep2;