import React from "react";
import {
  FormControl,
  FormLabel,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import InputCheckbox from "components/InputCheckbox";
import InputField from  "components/InputField";

const SubGrid = styled(Grid)(() => ({
  border: `1px solid #d9d9d9`,
  borderRadius: "4px",
  padding: 4,
}));

function InputMask({
  contentFields,
  controlForm,
  controlFields,
  controlName,
  ...props
}) {
  return (
    <FormControl fullWidth sx={{ paddingY: 4 }}>
      <SubGrid container rowSpacing={4}>
        <InputCheckbox
          {...props}
          isExclusive={true}
          exclusiveSubFields={controlFields}
        />
        {controlForm.values[controlName] ? (
          <>
            {Object.values(contentFields)
              .find((field) => (
                field.fieldName === controlName
              )).isExclusive
            ? (
              <Grid item xs={12}>
                <FormLabel component="legend">
                  <Typography variant="caption">
                    Puoi selezionare soltanto uno:
                  </Typography>
                </FormLabel>
              </Grid>
            ) : null}
            {controlFields.map((field) => (
              <Grid item
                xs={12}
                key={`subfield-${field.fieldName}`}
              >
                <InputField
                  contentFields={contentFields}
                  field={field}
                  form={controlForm}
                />
              </Grid>
            ))}
          </>
        ) : null}
      </SubGrid>
    </FormControl>
  );
};

export default InputMask;