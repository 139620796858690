import React, { useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Grid, Typography, Input, Box} from "@mui/material";
import {FormikProvider, useFormik} from "formik";
import * as yup from "yup";
import useRequests from "api/useRequests";
import useTheme from "api/siteTheme";
import {POST_FIELDS} from "api/postFields";
import InputField, {
	inputFileValidator,
	inputTextValidator,
} from "components/InputField";
import Icon from "components/Icon";
import Spinner from "components/Spinner";
import useCrypt from '@/utils/crypt.js';

export default function CreatePost() {
  	const userId = localStorage.getItem("UserId");
	const [open, setOpen] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const [responseText, setResponseText] = useState('');
	const [responseType, setResponseType] = useState('');
	const [responseRequestPost, setResponseRequestPost] = useState();
	const [loading, setLoading] = useState(false);
	const [resUploading, setResUploading] = useState('');
	const [file, setFile] = useState();
	
	const backofficeId = localStorage.getItem('BackofficeId');
	const urlPost = `/newsfeed/api/v1/newsfeed/share`;
	const theme = useTheme();
	const contentForm = POST_FIELDS;
	
	const navigate = useNavigate();
	
	const { aesEncrypt } = useCrypt();


	const contentFields = contentForm.fields?.flatMap((field) => {
		return field.fieldName === 'userId' ? {
				...field,
				// fieldValue: localStorage.getItem("UserId") || '',
				fieldValue: userId || '',
			} :
			[field]
	});
	
	const formik = useFormik({
		initialValues: getInitialValues(),
		validationSchema: getValidationSchema(),
		onSubmit: async (values, actions) => {
			await handleFormSubmit(contentFields, values);
			actions.setSubmitting(false)
		},
	});
	
	
	useEffect(() => {
		if (responseRequestPost) {
			if (Boolean(file) && Boolean(responseRequestPost?.[0]?.success)) {
				const newsFeedId = responseRequestPost?.[0]?.newsfeeds?.[0]?.newsfeedId;

				handleFileUpload(newsFeedId)
			} else {
				setLoading(false);
				setResponseType(responseRequestPost?.[0]?.status);
				setResponseText(Boolean(responseRequestPost?.[0]?.success)
					? 'Post creato correttamente'
					: responseRequestPost?.[0]?.message
						|| responseRequestPost?.[0]?.errorMessage
						||  responseRequestPost?.[0]?.toString()
						|| 'Errore'
				);
			}
		}
	}, [responseRequestPost])

	const requestToSend = useRequests();
	
	async function handleFormSubmit(contentFields, values) {
		const newValues = Boolean(backofficeId) && backofficeId !== 'null' ? {...values, backofficeId} : {...values};

		setDisableButton(true)
		if (newValues) {
			UpDateContent(newValues);
		}
	};

	async function UpDateContent(valuesToSendPost) {
		if(!Boolean(file) || (Boolean(file) && (file?.type === 'image/jpeg' ||
				file?.type === 'image/jpg' ||
				file?.type === 'image/png' ||
				file?.type === 'image/svg+xml'))) {
			setLoading(true);

			valuesToSendPost['imageUrl'] = null;

			let responseRequest;
			const request = requestToSend([valuesToSendPost], urlPost, 'POST', true, localStorage.getItem('Authorization'));
			responseRequest = await Promise.all(request)?.then(data => {
					setOpen(true);
					document.body.classList.add('has-mask')
					return data
				}
			)?.catch(error => {
				setLoading(false);
				setOpen(true);
				setResponseType('error');
				setResponseText('Qualcosa è andato storto');
				document.body.classList.add('has-mask')
				return error
			});

			setResponseRequestPost(responseRequest);
		}
	}

	
	document.body.addEventListener('click', (e) => {
		if (open && e.target.classList.contains('has-mask')) {
			document.body.classList.remove('has-mask')
			setOpen(false)
			window.location.reload()
			
		}
	})

	function isObject(value) {
		return (
			typeof value === 'object' &&
			value !== null &&
			!Array.isArray(value)
		);
	}

	const handleFileChange = (e) => {
		if (e?.target?.files) {
			setFile(e.target.files[0]);
			setResUploading('');

			if(Boolean(e.target.files[0]) && 
				e.target.files[0]?.type !== 'image/jpeg' &&
				e.target.files[0]?.type !== 'image/jpg' &&
				e.target.files[0]?.type !== 'image/png' &&
				e.target.files[0]?.type !== 'image/svg+xml') {
				setResUploading('supportato solo formato jpeg, jpg, png, svg+xml')
			}
		}
	};

	async function handleFileUpload(newsFeedId) {
		let formData = new FormData();
		formData.append('file', file);

		if(Boolean(file) && file?.type !== 'image/jpeg' &&
				file?.type !== 'image/jpg' &&
				file?.type !== 'image/png' &&
				file?.type !== 'image/svg+xml') {

			setResUploading('supportato solo formato jpeg, jpg, png, svg+xml')
			setFile(null)
			setLoading(false)
			setOpen(false)
		} else {
			let response;
			const backofficeId = localStorage.getItem('BackofficeId');
			const url = `/social/api/v1/upload/newsfeed?newsfeed_id=${newsFeedId}`;
			const token = localStorage.getItem("Authorization");
			const criptedData = aesEncrypt(formData);
			const fileUpload = requestToSend([criptedData], url, 'POST', true, token, false, true);
			response = await Promise.all(fileUpload)?.then(data => {
					setOpen(true);
					document.body.classList.add('has-mask')
					return data
				}
			)?.catch(error => {
				setLoading(false);
				setOpen(true);
				document.body.classList.add('has-mask')
				setResponseType('error');
				setResponseText('Qualcosa è andato storto');
				return error
			});
			if(Boolean(response)) {
				setLoading(false);
				setResponseType(response?.[0]?.status);
				setResponseText(response?.[0]?.status === 'success' || response?.[0]?.status === 200
					? 'Post creato correttamente'
					: response?.message
						|| response?.errorMessage
						||  response?.toString()
						|| 'Errore'
				);
			}
		}
	}

	const PopUp = () => {
		return (
			
			<Grid
				container
				height={'100%'}
				alignItems={'center'}
				className={open ? 'visible' : ''}
				sx={{
					transform: 'scale(0)',
					maxWidth: '600px',
					maxHeight: '300px',
					pointerEvents: 'none',
					position: 'fixed',
					top: '0',
					right: '0',
					bottom: '0',
					left: '0',
					margin: 'auto',
					borderRadius: '20px',
					boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 15px',
					transition: 'transform .5s linear .2s',
					background: 'white',
					padding: '16px',
					'&.visible': {
						transform: 'scale(1)',
						pointerEvents: 'auto',
						zIndex: '5',
					},
					[theme.breakpoints.down('sm')]: {
						margin: 'auto 16px',
						width:'auto'
					}
				}}
			>
				
				<Grid
					item
					xs={12}
					container
					display={'flex'}
					flexDirection={'column'}
					justifyContent={'space-between'}
					height={'200px'}
				>
					<Grid display={'flex'}
					      flexDirection={'column'}
					      justifyContent={'space-between'} alignItems={'center'}>
						<Typography
							sx={{
								color: responseType === 'success' ? '#4caf50' : '#f44336',
								textAlign: 'center',
								marginBottom: '16px'
							}}>{responseType === 'error' ? `si è verificato il seguente errore: ${responseText}` : `${responseText}`}</Typography>
						
						<Icon type={responseType === 'success' ? 'SmileIcon' : 'SadIcon'}
						      sx={{color: responseType === 'success' ? '#4caf50' : '#f44336'}} fontSize={'large'}/>
					</Grid>
					
					
					<Grid display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
						
						<Button
							fullwidth="true"
							type="submit"
							variant="contained"
							sx={{color: 'white', margin: '8px auto 0', display: 'flex', height: 'fit-content'}}
							onClick={() => {
								navigate('/');
								document.body.classList.remove('has-mask')
							}}
						>
							HomePage
						</Button>
						
						<Button
							fullwidth="true"
							type="submit"
							variant="contained"
							sx={{color: 'white', margin: '8px auto 0', display: 'flex', height: 'fit-content'}}
							onClick={() => window.location.reload()}
						>
							Crea Nuovo Post
						</Button>
					</Grid>
				</Grid>
			</Grid>
		)
	}
	
	
	return loading ? <Spinner/> :
		<Grid
			container
			justifyContent="space-between"
			flexWrap={'nowrap'}
			height={'100%'}
		> 
			<PopUp/>
			<Grid
				item
				xs={12}
				justifyContent="center"
				alignItems="flex-start"
				height={'100%'}
			>
				<Grid
					item xs={12}
					align="left"
					component={Typography}
					variant="h3"
					gutterBottom
					textTransform="capitalize"
					padding={'20px'}
					color={theme.palette.primary.main}
				>
					{contentForm.contentType}
				</Grid>
				<FormikProvider value={formik}>
					
					<Grid
						component="form"
						item xs={12}
						sx={{height: 'calc(100% - 180px)'}}
						onSubmit={(e) => {
							formik.handleSubmit();
							e.preventDefault()
						}}
					>
						<Grid justifyContent={'space-between'} display={'flex'} flexWrap={'nowrap'} height={'100%'}>
							<Grid
								item
								xs={12}
								container
								rowSpacing={4}
								display={'block'}
								padding={'16px 32px'}>
								{contentForm.fields.map((field, index) => {
									return (
										(field.fieldName === 'description' || field.fieldName === 'title') ? (
											<Grid key={`field-${index}`} item xs={12} height={'fit-content'}>
												<InputField
													contentFields={contentFields}
													field={field}
													form={formik}
													disabled={loading}
												/>
											</Grid>
										) : null
									)
								})}
								<Box>
									<Typography
										marginX={3}
										sx={{
											color: `${theme.palette.primary.main}`,
											margin: '0 0 4px 0',
											fontSize: '0.75rem'
										}}
									>
									{resUploading !== '' ?
										<span style={{color: '#f44336'}}> {resUploading}</span> : null}
										{file && !resUploading ?
											<span>File selezionato : {isObject(file) ? file?.name : file}</span> : null}
									</Typography>
									<Input
										sx={{
											alignItems: "center",
											border: 1,
											borderColor: "#d9d9d9",
											borderRadius: "4px",
											display: "flex",
											paddingY: 2,
											justifyContent: "space-between",
											width: "100%",
											padding: '9px',
											color: '#666666',
											'input': {
												'&::-webkit-file-upload-button': {
													background: 'rgb(51, 167, 178)',
													border: 'none',
													borderRadius: '4px',
													boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;',
													marginRight: '10px',
													cursor: 'pointer',
													transition: 'all .1s linear',
													color: 'white',
													'&:hover': {
														backgroundColor: 'rgb(35, 116, 124)',
														boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;'
													}
												}
											}
										}}

										id={'file'}
										name={'file'}
										label={'immagine post'}
										disableUnderline
										type="file"
										accept="file/*"
										disabled={loading}
										onChange={(event) => {
											event.preventDefault();
											handleFileChange(event);
										}}
									/>
								</Box>
							</Grid>
						</Grid>
						
						<Grid
							item
							xs={12}
							container
							fullwidth="true"
							justifyContent="center"
							position={'sticky'}
							bottom={0}
							right={0}
							left={0}
							style={{background: 'white', zIndex: '3', boxShadow: '0 0 15px rgba(0, 0, 0, 0.15)'}}
						>
							<Grid item padding={'16px'} width={'fit-content'}>
								<Button
									fullwidth="true"
									variant="outlined"
									onClick={() => {
										navigate('/');
										document.body.classList.remove('has-mask')
									}}
								
								>
									annulla
								</Button>
							</Grid>
							<Grid item width={'fit-content'} padding={'16px'}>
								<Button
									fullwidth="true"
									type="submit"
									variant="contained"
									sx={{color: 'white'}}
									disabled={disableButton || !!resUploading}
								>
									salva
								</Button>
							</Grid>
						</Grid>
					</Grid>
				
				</FormikProvider>
			</Grid>
		</Grid>
	
	function getInitialValues() {
		return contentFields.reduce((acc, field) => (
			{
				...acc,
				[field.fieldName]: field.fieldValue,
			}), {});
	};
	
	function getValidationSchema() {
		return yup.object(
			contentFields.reduce((acc, field) => ({
				...acc, ...getFieldValidation(field),
			}), {})
		)
	};
	
	function getFieldValidation(field) {
		
		switch (field.fieldType) {
			case  "image":
				return ({
					...inputFileValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					})
				});
			case "text":
				return ({
					...inputTextValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					})
				});
			case "html":
				return ({
					...inputTextValidator({
						controlLabel: field.fieldLabel,
						controlName: field.fieldName,
						controlIsRequired: field.required,
					})
				});
			default: {
			
			}
		}
		;
	};
};


