import { createContext } from 'react';
import { RouterProvider } from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
}
from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development'
// import { ReactQueryDevtools as ReactQueryDevtoolsProduction} from "@tanstack/react-query-devtools/production";
// cfr. https://tanstack.com/query/v4/docs/react/devtools#devtools-in-production
import { ThemeProvider } from "api/siteTheme";
// import useEnvironment from "api/useEnvironment";
import GlobalStyle from './global-styles';
import router from './router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * 60 * 1000, //3 min
    },
  }
})

export const AssignContext = createContext(null)
// export const AuthContext = createContext()

function App() {
  // const {
  //   // environment,
  //   mode
  // } = useEnvironment()
  // const authState = useState({
  //   authorization: environment === "mock" ? "auth-mock" : null,
  //   userId: environment === "mock" ? "userid-mock" : null
  // })
  // const [_, setAuthStore] = authState

  // useEffect(() => {
  //   const PERMITTED_DOMAIN = "https://prelios.sit.digitedacademy.net";
  //   function messageHandler(event) {
	// 		if (event.origin === PERMITTED_DOMAIN && event.data) {
  //       const data = JSON.parse(event.data)
  //       if (Boolean(data.Authorization) && Boolean(data.userId)) {
  //         localStorage.setItem("Authorization", data.Authorization);
  //         localStorage.setItem("UserId", data.userId);
  //         setAuthStore({
  //           authorization: data.Authorization,
  //           userId: data.userId
  //         })
  //       }
  //     }
	// 		// } else {
  //     //   localStorage.removeItem("Authorization");
  //     //   localStorage.removeItem("UserId");
  //     //   setAuthorization(null)
  //     //   setUserId(null)
	// 	}
	// 	window.addEventListener('message', messageHandler);
  //   return window.removeEventListener("message", messageHandler)
	// }, [setAuthStore]);

  return (
    <>
      {/* TODO: switch to MUI <CssBaseline /> for globals? */}
      <GlobalStyle /> {/* resets (styled components) */}
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          {/* <AuthContext.Provider value={authState}> */}
            <RouterProvider router={router} />
          {/* </AuthContext.Provider> */}
          <ReactQueryDevtools initialIsOpen={false} />
          {/* { mode === "production" ? (
            <ReactQueryDevtoolsProduction initialIsOpen={false} />
          ) : null} */}
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
