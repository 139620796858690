import styled from "styled-components";

export const BoxChart = styled.div`
  box-sizing: border-box;
  margin: 10px;
  height: 85vh;
  padding-right: 15px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 10px 0px;
  border-radius: 15px;
  margin-top: 10px;
  padding-bottom: 40px;
  &:first-child{
      margin-left: 0;
    }
  &:last-child{
    margin-right: 0;
  }
  .button-container{
    display: flex;
    justify-content: flex-end;
    .change-year{
      width: 200px;
      background-color: #DFDFDF;
      color: #515151;
      padding: 2px 10px;
      border-radius: 20px;
      border: 0;
      font-weight: bold;
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg{
        cursor: pointer;
      }
    }
  }
  
`