import React from "react";
import {
	Select,
	MenuItem,
	FormControl,
	InputLabel,
} from "@mui/material";
import * as yup from "yup";
import useTheme from "api/siteTheme";
import {resetGroupFields} from "components/InputField";

function InputSimpleSelect({
	contentFields,
	controlForm,
	controlGroup,
	controlLabel,
	controlName,
	required
}) {
	const theme = useTheme();
	const options = controlName === 'question_type' ? [
		{
			name: 'Domanda a risposta multipla',
			value: 'question_multiple'
		},
		{
			name: 'Domanda a risposta singola',
			value: 'question_single'
		},
		{
			name: 'Valutazione questionario',
			value: 'question_evaluation'
		},
		{
			name: 'Domanda Like/Dislike',
			value: 'question_like_dislike'
		},
		{
			name: 'Domanda a risposta aperta',
			value: 'question_open'
		}
	] : controlName === 'mode' ? [
			{
				name: 'Virtuale',
				value: 'virtual'
			},
			{
				name: 'In presenza',
				value: 'live'
			}
		] :
		controlName === 'calendar_choice' ? [
				
				{
					name: 'Preselezionata',
					value: 'preselected'
				},
				{
					name: 'Libera',
					value: 'free'
				},
				{
					name: 'Nessuna',
					value: ''
				}
		] :
		[
			{
				name: 'Web',
				value: 'web'
			},
			{
				name: 'Video',
				value: 'video'
			}
		]

	return (
		<FormControl fullWidth>
			<InputLabel id="demo-simple-select-label">{`${controlLabel}${required ? '*' : ''}`}</InputLabel>
			<Select
				sx={{
					'& .MuiOutlinedInput-root': {
						'&:hover fieldset': {
							borderColor: `${theme.palette.primary.main}`,
						},
					},
				}}
				id={controlName}
				name={controlName}
				label={`${controlLabel}${required ? '*' : ''}`}
				fullWidth
				value={controlForm.values[controlName]}
				onChange={handleChange}
				error={controlForm.touched[controlName]
					&& Boolean(controlForm.errors[controlName])}
				helperText={controlForm.touched[controlName]
					&& controlForm.errors[controlName]}
			>
				{
					options.length > 0 ? options.map(option => {
							return (
								<MenuItem value={option.value}>{option.name}</MenuItem>
							)
						}) :
						
						<span>nessuna opzione disponibile</span>
				}
			
			</Select>
		</FormControl>
	
	
	);
	
	function handleChange(value) {
		resetGroupFields(
			contentFields,
			controlForm,
			controlGroup,
			controlName
		);
		
		controlForm.handleChange(value);
	};
};

export const inputSimpleSelectValidator = ({
	                                           controlLabel,
	                                           controlName,
	                                           controlIsRequired
                                           }) => {
	const validator = {
		[controlName]: yup
			.string(`inserisci un valore per ${controlLabel}`)
	};
	if (controlIsRequired) {
		validator[controlName] =
			validator[controlName]
				.required(`${controlLabel} è obbligatorio`);
	}
	;
	return validator;
};

export default InputSimpleSelect;
